// TODO: replace core Select component with this one

type SelectProps = React.DetailedHTMLProps<
  React.SelectHTMLAttributes<HTMLSelectElement>,
  HTMLSelectElement
> & {
  label: string;
};

const Select: React.FC<SelectProps> = ({ label, children, ...props }) => {
  return (
    <div className="relative">
      {label && (
        <label
          htmlFor={props.id || props.name}
          className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-500"
        >
          {label}
        </label>
      )}
      <select
        className="block w-full rounded-md border-0 py-2.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary tablet:text-sm tablet:leading-6"
        {...props}
      >
        {children}
      </select>
    </div>
  );
};

export default Select;
