import Card from "components/Card";
import { Alert, Button } from "components/core";
import Input from "components/config/input";
import Select from "components/config/select";
import FieldsetSkeleton from "components/core/Forms/FieldsetSkeleton";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import {
  useUserCreate,
  useUserDelete,
  useUserGetAssignedFarms,
  useUserGetOneById,
  useUserGetOrgLevels,
  useUserGetPermissionGroups,
  useUserUpdate,
} from "hooks/useUser";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Legend from "../legend";
import FarmSelect from "./farm-select";

const minUsernameLength = 3;
const maxUsernameLength = 20;

const UserForm: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const isEditing = !isNullEmptyOrWhitespace(id);
  const {
    data: user,
    isLoading: isLoadingUser,
    error: errorUser,
  } = useUserGetOneById({
    enabled: isEditing,
    id: id ?? "",
  });

  const {
    isLoading: isLoadingAssignedFarms,
    error: errorAssignedFarms,
    data: assignedFarms,
  } = useUserGetAssignedFarms({
    enabled: isEditing,
    id: id ?? "",
  });

  const {
    data: orgLevels,
    isLoading: isLoadingOrgLevels,
    error: errorOrgLevels,
  } = useUserGetOrgLevels({
    enabled: true,
  });

  const {
    data: permissionGroups,
    isLoading: isLoadingPermissionGroups,
    error: errorPermissionGroups,
  } = useUserGetPermissionGroups({
    enabled: true,
  });

  const {
    mutate: mutateCreateUser,
    error: createError,
  } = useUserCreate({
    onSuccess: (response, responseBody) => {
      const message = responseBody?.message ?? "User created successfully.";
      toast.success(message);

      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.controller?.postMessage({
          type: "INVALIDATE_CACHE",
          cacheName: "api",
          url: "/api/users-get"
        });
      }

      navigate("/admin/config/user");
    },
    onError: (errMessage) => {
      toast.error(errMessage ?? "Error creating user");
    },
  });

  const {
    mutate: mutateUpdateUser,
    error: editError,
  } = useUserUpdate({
    onSuccess: (response, responseBody) => {
      const message = responseBody?.message ?? "User updated successfully.";
      toast.success(message);

      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.controller?.postMessage({
          type: "INVALIDATE_CACHE",
          cacheName: "api",
          url: "/api/users-get"
        });
      }

      navigate("/admin/config/user");
    },
    onError: (errMessage) => {
      toast.error(errMessage ?? "Error updating user");
    },
  });

  const {
    mutate: deleteMutate,
    error: deleteError,
  } = useUserDelete({
    onSuccess: (response, responseBody) => {
      const message = responseBody?.message ?? "User deleted successfully.";
      toast.success(message);

      navigate("/admin/config/user");
    },
    onError: (errMessage) => {
      toast.error(errMessage ?? "Error deleted user");
    },
  });

  const [checkedFarms, setCheckedFarms] = useState<string[]>(
    assignedFarms?.map((f) => f.farmcode) || []
  );
  const [dotnetPermissionGroupId, setDotnetPermissionGroupId] =
    useState<string>(isEditing ? user?.permissionGroupId ?? "" : "");
  const [dotnetOrganisationLevelId, setDotnetOrganisationLevelId] =
    useState<string>(isEditing ? user?.permissionLevelId?.toString() ?? "" : "");
  const [passwordError, setPasswordError] = useState("");
  const [usernameError, setUsernameError] = useState("");

  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setCheckedFarms(assignedFarms?.map((f) => f.farmcode) || []);
  }, [assignedFarms]);

  useEffect(() => {
    setDotnetPermissionGroupId(isEditing ? user?.permissionGroupId ?? "" : "");
  }, [isEditing, user?.permissionGroupId]);

  useEffect(() => {
    setDotnetOrganisationLevelId(
      isEditing ? user?.permissionLevelId?.toString() ?? "" : ""
    );
  }, [isEditing, user?.permissionLevelId]);

  if (
    isLoadingOrgLevels ||
    isLoadingPermissionGroups ||
    (isEditing && isLoadingUser) ||
    (isEditing && isLoadingAssignedFarms)
  ) {
    return (
      <Card>
        <FieldsetSkeleton />
      </Card>
    );
  }

  if (
    errorUser ||
    errorAssignedFarms ||
    errorOrgLevels ||
    errorPermissionGroups
  ) {
    return <Alert theme="danger">Error loading user data.</Alert>;
  }

  if (isEditing && !user) {
    return <Alert theme="danger">User not found</Alert>;
  }

  // const [formData, setFormData] = useState({
  //   accountEnabled: true,
  //   displayName: "",
  //   mailNickname: "",
  //   emailAddress: "",
  //   password: "",
  //   forceChangePasswordNextSignIn: true,
  // });

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setFormData({
  //     ...formData,
  //     [event.target.name]: event.target.value,
  //   });
  // };

  const handlePasswordChange = () => {
    if (passwordRef.current === null || confirmPasswordRef.current === null) {
      setPasswordError("");

      return;
    }

    if (passwordRef.current.value !== confirmPasswordRef.current.value) {
        setPasswordError("Passwords do not match");
        confirmPasswordRef.current.setCustomValidity('Passwords do not match');
    } else {
        setPasswordError("");
        confirmPasswordRef.current.setCustomValidity('');
    }
  };

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "") {
      setUsernameError("Username is required");

      return;
    }

    if (event.target.value.length < minUsernameLength || event.target.value.length > maxUsernameLength) {
      setUsernameError(`Username must be between ${minUsernameLength} and ${maxUsernameLength} characters`);

      return;
    }

    setUsernameError("");
  }

  const selectedPermissionGroup = permissionGroups?.find(
    (group) => group.id.toString() === dotnetPermissionGroupId
  );

  const selectedPermissionLevel = orgLevels?.find(
    (level) => level.id.toString() === dotnetOrganisationLevelId
  );

  return (
    <form
      className="space-y-4"
      onSubmit={(e) => {
        e.preventDefault();

        const formData = new FormData(e.currentTarget);
        const displayName = formData.get("displayName")?.toString();
        const emailAddress = formData.get("emailAddress")?.toString();
        const username = formData.get("username")?.toString();
        const password = formData.get("password")?.toString();
        const confirmPassword = formData.get("confirmPassword")?.toString();
        const assignedFarms =
          selectedPermissionLevel?.id !== 0 && // Administrator
          selectedPermissionGroup?.farmSelectionAccess === "Assigned Farms"
            ? checkedFarms
            : [];

        // Validate form data
        if (!displayName) {
          throw new Error("Display name is required");
        }
        if (!emailAddress) {
          throw new Error("User principal name is required");
        }
        if (!dotnetOrganisationLevelId) {
          throw new Error("Organisation Level is required");
        }
        if (!dotnetPermissionGroupId) {
          throw new Error("Permission Group is required");
        }

        if (isEditing && user?.id) {
          const editPayload = password
            ? {
                id: user.id,
                data: {
                  displayName: displayName,
                  emailAddress: emailAddress,
                  dotnetOrganisationLevelId,
                  dotnetPermissionGroupId,
                  password,
                },
              }
            : {
                id: user.id,
                data: {
                  /* We can only change the following fields */
                  displayName: displayName,
                  emailAddress: emailAddress,
                  dotnetOrganisationLevelId,
                  dotnetPermissionGroupId,
                  assignedFarms,
                },
              };

          mutateUpdateUser(editPayload);
        } else {
          if (!username) {
            throw new Error("Username is required");
          }
          if (username.length < minUsernameLength || username.length > maxUsernameLength) {
            throw new Error(`Username must be between ${minUsernameLength} and ${maxUsernameLength} characters`);
          }
          if (!password) {
            throw new Error("Password is required");
          }
          if (!confirmPassword) {
            throw new Error("Confirm password is required");
          }
          if (password !== confirmPassword) {
            throw new Error("Passwords do not match");
          }

          mutateCreateUser({
            accountEnabled: true,
            displayName: displayName,
            emailAddress: emailAddress,
            username: username,
            forceChangePasswordNextSignIn: false, // If set to true it will display "The password has expired."
            dotnetOrganisationLevelId,
            dotnetPermissionGroupId,
            assignedFarms,
            password,
          });
        }
      }}
    >
      {createError && (
        <Alert theme="danger" className="mb-2">
          {createError}
        </Alert>
      )}
      {editError && (
        <Alert theme="danger" className="mb-2">
          {editError}
        </Alert>
      )}
      {deleteError && (
        <Alert theme="danger" className="mb-2">
          {deleteError}
        </Alert>
      )}
      <Card>
        <fieldset className="mb-4 space-y-4">
          <Legend
            className="mb-4"
            title="User Information"
            description="Enter user details."
          />
          <Input
            label="Display Name"
            name="displayName"
            placeholder="E.g. John Doe"
            required
            defaultValue={isEditing ? user?.fullname ?? "" : ""}
          />
          {/* <Input
            label="Mail Nickname"
            name="mailNickname"
            placeholder="E.g. john.doe"
            required
          /> */}
          <Input
            label="Email Address"
            name="emailAddress"
            placeholder="E.g. user@company.com"
            required
            defaultValue={isEditing ? user?.email ?? "" : ""}
          />
          {/**
           * The inclusion of username here makes no sense
           * because we use the email address but is needed for legacy reasons.
           * It is also disabled for editing because it cannot be changed without creating a duplicate
           * user in Legacy DB.
           */}
          <Input
            label="User name"
            name="username"
            placeholder="E.g. joebloggs"
            required
            defaultValue={isEditing ? user?.username ?? "" : ""}
            disabled={isEditing}
            onChange={handleUsernameChange}
          />
          {usernameError && <div className="text-danger-500 text-sm">{usernameError}</div>}
          <Input
            label="Password"
            name="password"
            type="password"
            placeholder="********"
            required
            disabled={isEditing}
            ref={passwordRef}
            onChange={handlePasswordChange}
            // Don't set a defaultValue for passwords for security reasons.
          />
          <Input
            label="Confirm Password"
            name="confirmPassword"
            type="password"
            placeholder="********"
            required
            disabled={isEditing}
            ref={confirmPasswordRef}
            onChange={handlePasswordChange}
          />
          {passwordError && <div className="text-danger-500 text-sm">{passwordError}</div>}
        </fieldset>
      </Card>
      <Card>
        <fieldset className="mb-4 space-y-4">
          <Legend
            className="mb-4"
            title="Role Information"
            description="Enter role details."
          />
          <Select
            label="Desktop App Organisation Level"
            name="dotnetOrganisationLevel"
            placeholder="Organisation Level"
            required
            value={dotnetOrganisationLevelId}
            onChange={(e) => setDotnetOrganisationLevelId(e.target.value)}
            // defaultValue={isEditing ? user?.permissionLevelId ?? "" : ""}
          >
            <option value="">Select an Organisation Level</option>
            {orgLevels?.map((level) => (
              <option key={level.id} value={level.id}>
                {level.name}
              </option>
            ))}
          </Select>
          <Select
            label="Desktop App Permission Group"
            name="dotnetPermissionGroup"
            placeholder="Permission Group"
            required
            value={dotnetPermissionGroupId}
            onChange={(e) => setDotnetPermissionGroupId(e.target.value)}
            // defaultValue={shouldLoadUser ? user?.permissionGroupId ?? "" : ""}
          >
            <option value="">Select an Permission Group</option>
            {permissionGroups
              ?.filter((g) => !["0"].includes(g.id?.toString()))
              ?.map((level) => (
                <option key={level.id} value={level.id}>
                  {level.name}
                </option>
              ))}
          </Select>
        </fieldset>
      </Card>
      <Card>
        <fieldset className="mb-4 space-y-4">
          <Legend
            className="mb-4"
            title="Farms"
            description="Select the farms this user has access to."
          />
          {selectedPermissionLevel?.id !== 0 && // Administrator
          selectedPermissionGroup?.farmSelectionAccess === "Assigned Farms" ? (
            <FarmSelect
              checkedFarms={checkedFarms}
              setCheckedFarms={setCheckedFarms}
            />
          ) : isNullEmptyOrWhitespace(
              selectedPermissionGroup?.farmSelectionAccess
            ) ? (
            <Alert theme="warning">
              This user cannot access any farms. Individual farms cannot be
              selected.
            </Alert>
          ) : (
            <Alert theme="success">
              This user has access to{" "}
              {selectedPermissionLevel?.id === 0
                ? "All Farms"
                : selectedPermissionGroup?.farmSelectionAccess}
              . Individual farms cannot be selected.
            </Alert>
          )}
        </fieldset>
      </Card>
      <div className="flex flex-col-reverse tablet:flex-row tablet:justify-between tablet:space-x-2 tablet:space-y-0">
        <div className="space-x-2">
          <Button
            className="w-full tablet:w-auto"
            type="button"
            onClick={() => void navigate("/admin/config/user")}
          >
            Cancel
          </Button>
          {isEditing && (
            <Button
              type="button"
              onClick={() => {
                if (
                  window.confirm("Are you sure you want to delete this user?")
                ) {
                  void deleteMutate(id!);
                }
              }}
            >
              Delete
            </Button>
          )}
        </div>
        <Button
          className="mb-2 w-full tablet:mb-0 tablet:w-auto"
          theme="primary"
          type="submit"
        >
          {isEditing && user ? "Update user" : "Create user"}
        </Button>
      </div>
    </form>
  );
};

export default UserForm;
