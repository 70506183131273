import { useNavigate } from "react-router-dom";
import { Button } from "./core";
import { ChevronLeftIcon } from "assets/icons";

const BackButton: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Button
      size="small"
      theme="text"
      className="mr-2"
      onClick={() => navigate(-1)}
    >
      <ChevronLeftIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
      Back to previous page
    </Button>
  )
};

export default BackButton;