/**
 * RepeatedFormFields
 * Current restrictions: all fields are grouped together using the RepeaterID
 * therefore we cannot have more than one repeated field group per 'level' e.g. house, pen
 */

import { PlusCircleIcon, TrashIcon } from "assets/icons";
import {
  // getFormFieldValueFromRepeaterData,
  getFormValueFromPenData,
  getHighestIndexFromRepeaterRefs,
  getRepeaterFieldValuesFromPenData,
  // getRepeaterFieldValuesFromPenData,
  type IFormField,
  type IPenData,
  type IRepeaterField,
  parseCustomLogic,
} from "helpers/formUtilities";
import FormField from "./FormField";
import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import { type IFormFieldProps } from "./types/FormField";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { type IFarm, type IFarmHouse } from "helpers/farmUtilities";

interface IRepeatedFormFieldsProps
  extends Omit<IFormFieldProps, "field" | "formValue"> {
  id: string;
  label: string;
  minRepeats: number;
  minRepeatsQuery: string;
  maxRepeats: number;
  maxRepeatsQuery: string;
  fields: IFormField[];
  type: IRepeaterField["Type"];
  penData: IPenData | undefined;
  farm: IFarm;
  house: IFarmHouse;
}

const RepeatedFormFields = ({
  id,
  label,
  minRepeats,
  minRepeatsQuery,
  maxRepeats,
  maxRepeatsQuery,
  fields,
  type,
  penData,
  penNumber,
  setFieldValue,
  removeFieldValid,
  farm,
  house,
  ...rest
}: IRepeatedFormFieldsProps) => {
  const repeaterFieldValues = useMemo(() => {
    return getRepeaterFieldValuesFromPenData(fields, id, penData);
  }, [fields, id, penData]);

  const highestIndex = useMemo(() => {
    if (!repeaterFieldValues) return;
    
    const valueRefs = repeaterFieldValues.map((fieldValue) => fieldValue.Ref);
    return getHighestIndexFromRepeaterRefs(valueRefs);
  }, [repeaterFieldValues]);

  const _minRepeats = useMemo(() => {
    let _minRepeats = minRepeats;
    if (!isNullEmptyOrWhitespace(minRepeatsQuery)) {
      const parsedLogic = parseCustomLogic(
        minRepeatsQuery,
        {
          _farm: farm,
        },
        {
          Ref: id,
          GroupId: "",
          PenId: penNumber,
          BirdType: "",
          BirdSex: "",
          BirdAge: { Days: 0, Weeks: 0 },
          FarmGroup: "",
          FormDate: new Date(),
        }
      );

      if (!isNullEmptyOrWhitespace(parsedLogic) && !isNaN(Number(parsedLogic))) {
        _minRepeats = Number(parsedLogic);
      }

      return _minRepeats;
    } 
    
    if (highestIndex === undefined) return _minRepeats;
    if (highestIndex > _minRepeats) return highestIndex;

    return _minRepeats;
  }, [minRepeats, minRepeatsQuery, highestIndex, farm, id, penNumber]);

  const _maxRepeats = useMemo(() => {
    let _maxRepeats = maxRepeats;
    if (!isNullEmptyOrWhitespace(maxRepeatsQuery)) {
      const parsedLogic = parseCustomLogic(
        maxRepeatsQuery,
        {
          _farm: farm,
        },
        {
          Ref: id,
          GroupId: "",
          PenId: penNumber,
          BirdType: "",
          BirdSex: "",
          BirdAge: { Days: 0, Weeks: 0 },
          FarmGroup: "",
          FormDate: new Date(),
        }
      );
      if (!isNullEmptyOrWhitespace(parsedLogic) && !isNaN(Number(parsedLogic))) {
        _maxRepeats = Number(parsedLogic);
      }
    }

    return _maxRepeats;
  }, [maxRepeats, maxRepeatsQuery, farm, id, penNumber]);

  const [minRepeats2, setMinRepeats2] = useState(_minRepeats);

  const groupIndicies = useMemo(
    () => Array.from({ length: minRepeats2 }, (_, i) => i + 1),
    [minRepeats2]
  );

  const onClickAddRepeatedField = () => {
    setMinRepeats2((prev) => {
      if (prev + 1 > _maxRepeats) return _maxRepeats;
      return prev + 1;
    });
  };

  const onClickRemoveRepeatedField = () => {
    setMinRepeats2((prev) => {
      if (prev - 1 < _minRepeats) return _minRepeats;
      return prev - 1;
    });

    // const repeaterFieldValues = getRepeaterFieldValuesFromPenData(id, penData);

    const currentGroupIndex = groupIndicies.length;

    // remove form values for current group
    fields.forEach((field) => {
      const currentFormValue = getFormValueFromPenData({
        ref: `${field.Ref}_${currentGroupIndex}`,
        questionGroup: field.QuestionGroup,
        penData,
      });
      // const currentFormValue = getFormFieldValueFromRepeaterData(field, currentGroupIndex, repeaterFieldValues);

      if (currentFormValue) {
        const newFormValue = {
          ...currentFormValue,
          Value: undefined,
        };
        setFieldValue(field, penNumber, newFormValue);
      }
    });

    // remove form valid for current group
    fields.forEach((field) => {
      if (removeFieldValid) {
        removeFieldValid(`${field.Ref}_${currentGroupIndex}`, field, penNumber);
      }
    });
  };

  useEffect(() => {
    setMinRepeats2(_minRepeats);
  }, [_minRepeats]);

  if (groupIndicies.length === 0) {
    return null;
  }

  return (
    <div className="col-span-full space-y-8 divide-y divide-gray-200">
      {groupIndicies.map((groupIndex, index) => (
        <div
          key={`repeated-field-group-${groupIndex}`}
          className={classNames(index !== 0 ? "pt-8" : "")}
        >
          <div className="grid grid-cols-4 gap-4">
            <RepeaterFormFieldLabel
              defaultLabel={label}
              index={index}
              type={type}
              house={house}
            />
            {fields.map((field) => (
              <RepeatedFormField
                {...rest}
                key={`${field.Ref}_${field.RepeaterID}_${groupIndex}`}
                id={`${field.Ref}_${groupIndex}`}
                index={groupIndex}
                field={field}
                farm={farm}
                penData={penData}
                penNumber={penNumber}
                setFieldValue={setFieldValue}
              />
            ))}
          </div>
        </div>
      ))}
      <div className="col-span-full space-x-4 flex items-center pt-4 pb-4">
        {groupIndicies.length < _maxRepeats ? (
          <ButtonAddRepeatedField
            onClick={onClickAddRepeatedField}
            count={groupIndicies.length}
            max={_maxRepeats}
          />
        ) : null}
        {groupIndicies.length > _minRepeats ? (
          <ButtonRemoveRepeatedField onClick={onClickRemoveRepeatedField} />
        ) : null}
      </div>
    </div>
  );

  // return (
  //   <div>
  //     {fields.map((field, index) => (
  //       <FormField
  //         key={`${field.Ref}-${field.Level}${penNumber}`}
  //         field={field}
  //         penNumber={penNumber}
  //         formValues={formValues}
  //         prevFormValues={prevFormValues}
  //         setFieldValue={setFieldValue}
  //         setFieldValid={setFieldValid}
  //         validateField={validateField}
  //         standards={standards}
  //         birdAge={birdAge}
  //         house={house}
  //         setIsCollapsible={setIsCollapsible}
  //         collapsed={collapsed}
  //         labelPosition={undefined}
  //         labelSize={undefined}
  //       />
  //     ))}
  //   </div>
  // );
};

function ButtonAddRepeatedField({
  onClick,
  count,
  max,
}: {
  onClick: () => void;
  count: number;
  max: number;
}) {
  return (
    <div
      className="button-add-repeated-field cursor-pointer text-gray-500 hover:text-primary flex items-center text-xs"
      onClick={onClick}
    >
      <PlusCircleIcon className="w-6 h-6 mr-2" />
      <span>
        Add field ({count}/{max})
      </span>
    </div>
  );
}

function ButtonRemoveRepeatedField({ onClick }: { onClick: () => void }) {
  return (
    <div
      className="button-remove-repeated-field cursor-pointer text-gray-500 hover:text-primary flex items-center text-xs"
      onClick={onClick}
    >
      <TrashIcon className="w-6 h-6 mr-2" />
      <span>Remove field</span>{" "}
    </div>
  );
}

interface IRepeatedFormFieldProps extends Omit<IFormFieldProps, "formValue"> {
  id: string;
  field: IFormField;
  index: number;
  penNumber: string;
  penData: IPenData | undefined;
}

function RepeatedFormField({
  id,
  field,
  index,
  penNumber,
  penData,
  ...rest
}: IRepeatedFormFieldProps) {
  return (
    <FormField
      {...rest}
      key={`${field.Ref}_${field.RepeaterID}_${index}`}
      id={id}
      field={field}
      penNumber={penNumber}
      formValues={penData}
    />
  );
}

function RepeaterFormFieldLabel(
  props: {
    defaultLabel?: string;
    index: number;
  } & ({
    type: "bin";
    house: IFarmHouse;
  } |  {
    type: undefined;
  })
) {
  if (props.type === "bin") {
    const binLabels = props.house.Bins ? props.house.Bins?.map((bin) => bin.Label) : [];

    return (
      <h3 className="col-span-full">
        {binLabels?.[props.index] ??
          `${props.defaultLabel} ${props.index + 1}`}
      </h3>
    );
  }

  return (
    <h3 className="col-span-full">
      {`${props.defaultLabel} ${props.index + 1}`}
    </h3>
  );
}

export default React.memo(RepeatedFormFields);
