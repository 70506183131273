import {
  localDateToSQLDate,
} from "helpers/dateUtilities";
import { isNullEmptyOrWhitespace } from "helpers/common";
import {
  getCurrentRecord,
  getPreviousRecord,
  useFormDataGetManyByFormId,
  useFormDataGetOnePlusPreviousById,
} from "./useFormData";
import { IFormData } from "helpers/formUtilities";
import { useMemo } from "react";


export function getRecordPWAIDFromPenValues(
  formValues: IFormData
): string | undefined {
  return formValues.PenValues?.find((pv) => pv.Pen.toString() === "1")
    ?.Values.find((v) => v.Ref.toLowerCase() === "pwaid")
    ?.Value?.toString();
}

export const useDynamicFormData = ({
  enabled = true,
  formId,
  formType,
  id, // record ID | PWAID | timestamp
  farmId,
  houseId,
  module,
  fromDate,
  toDate,
  shouldFetchById = false,
  //shouldFetchPreviousRecord = false, // TODO: implement this for performance
}: {
  enabled?: boolean;
  formId?: string;
  formType?: string;
  id?: string;
  farmId?: string;
  houseId?: string;
  module?: string;
  fromDate?: Date;
  toDate?: Date;
  shouldFetchById?: boolean;
  //shouldFetchPreviousRecord?: boolean;
}) => {
  const {
    isLoading: isLoadingFormDatas,
    isFetched: isFetchedFormDatas,
    error: errorFormDatas,
    data: formDatas,
  } = useFormDataGetManyByFormId({
    enabled:
      enabled &&
      !shouldFetchById && // Don't load form data if we're loading by ID
      !isNullEmptyOrWhitespace(farmId) &&
      !isNullEmptyOrWhitespace(houseId) &&
      !isNullEmptyOrWhitespace(formId) &&
      !isNullEmptyOrWhitespace(formType) &&
      !isNullEmptyOrWhitespace(module) &&
      !isNullEmptyOrWhitespace(fromDate) &&
      !isNullEmptyOrWhitespace(toDate),
    farmId: farmId ?? "",
    houseId: parseInt(houseId ?? "0"),
    formId: formId ?? "",
    formType: formType?.toLowerCase() ?? "",
    moduleId: module ?? "",
    fromDate: localDateToSQLDate(fromDate) ?? "",
    toDate: localDateToSQLDate(toDate) ?? "",
  });

  const {
    isLoading: isLoadingFormDatasById,
    isFetched: isFetchedFormDatasById,
    error: errorFormDatasById,
    data: formDatasById,
  } = useFormDataGetOnePlusPreviousById({
    enabled: 
      enabled &&
      shouldFetchById &&  // Load form data if we're loading by ID
      !isNullEmptyOrWhitespace(id) &&
      !isNullEmptyOrWhitespace(formId) &&
      !isNullEmptyOrWhitespace(formType) &&
      !isNullEmptyOrWhitespace(module),
    id: id ?? "",
    farmId: farmId ?? "",
    formId: formId ?? "",
    formType: formType?.toLowerCase() ?? "",
    moduleId: module ?? "",
  });

  const currentFormDataRecord = useMemo(
    () => getCurrentRecord(formDatas, id, formType, formId),
    [formDatas, id, formType, formId]
  );

  const prevFormDataRecord = useMemo(
    () => getPreviousRecord(currentFormDataRecord, formDatas, id, formType),
    [currentFormDataRecord, formDatas, id, formType]
  );

  const getData = () => {
    if (shouldFetchById) {
      return formDatasById?.[0];
    }

    return currentFormDataRecord;
  }

  const getPreviousData = () => {
    if (shouldFetchById) {
      return formDatasById?.[1];
    }

    return prevFormDataRecord;
  }

  return {
    isLoading: shouldFetchById ? isLoadingFormDatasById : isLoadingFormDatas,
    isFetched: shouldFetchById ? isFetchedFormDatasById : isFetchedFormDatas,
    error: shouldFetchById ? errorFormDatasById : errorFormDatas,
    data: getData(),
    previousData: getPreviousData(),
  };
};
