import { searchParamsSchema } from "pages/config/standards";
import { Button } from "components/core/Buttons/Button";
import Card from "components/Card";
import { useEffect, useState } from "react";
import { NewStandardsModal } from "./NewStandardsModel";
import { DateTime } from "luxon";

type BirdType = {
  label: string;
  value: string;
};

type FromDate = {
  label: string;
  _filter: string;
};

export type onDuplicateProps = {
  farmType: string;
  birdType: string;
  frequency: string;
  fromDate: string;
  newDate: string;
};

type SearchProps = {
  onSearch: (params: searchParamsSchema) => void;
  birdTypes: BirdType[];
  fromDates: FromDate[];
  onDuplicate: (params: onDuplicateProps) => void;
};

const Search: React.FC<SearchProps> = ({
  onSearch,
  onDuplicate,
  birdTypes,
  fromDates,
}) => {
  const [farmType, setFarmType] = useState("");
  const [birdType, setBirdType] = useState("");
  const [frequency, setFrequency] = useState("");
  const [fromDate, setFromDate] = useState("");
  // const [newDate, setNewDate] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const parseDate = (dateString: string) => {
    const [day, month, year] = dateString.split(" ");
    return new Date(`${day} ${month} ${year}`);
  };

  const callCopyStandards = (formData: FormData) => {
    const newDate = (formData.get("date") ?? "").toString();
    console.log("callCopyStandards clicked", newDate);
    console.log("formData= ", formData);
    console.log("farmType= ", farmType);
    console.log("newDate= ", newDate);
    //console.log ("somethingelse= " ,formData.get("somethingelse"))

    onDuplicate({ farmType, birdType, frequency, fromDate, newDate });
    setFromDate(DateTime.fromISO(newDate).toFormat("dd LLL yyyy"));
    setIsOpen(false);
  };

  const handleClickNew = () => {
    console.log("clicked new!");
    setIsOpen(true);
  };

  useEffect(() => {
    const isBasicInfoIncomplete = !farmType || !birdType || !frequency;

    if (isBasicInfoIncomplete) {
      setIsButtonDisabled(true);
    } else {
      const filterString = `${farmType}_${birdType}_${frequency}`.toLowerCase();
      const matchingEntries = fromDates.filter(
        (d) => d._filter.toLowerCase() === filterString
      ).length;

      //console.log("matchingEntries", matchingEntries)
      //console.log("!fromDate", !fromDate)

      if (matchingEntries === 0 && !fromDate) {
        setIsButtonDisabled(false); // Enable if no matching entries and fromDate is not selected
        //console.log("Ryan1")
      } else if (matchingEntries > 0 && !fromDate) {
        setIsButtonDisabled(true); // Disable if there are matching entries and fromDate is not selected
        //console.log("Ryan2")
      } else {
        if (fromDate) {
          setIsButtonDisabled(false); // Enable if fromDate is selected
          //console.log("Ryan3")
        }
      }
    }
  }, [farmType, birdType, frequency, fromDate, fromDates]);

  return (
    <>
      <div className="col-span-full">
        <form
          onSubmit={(ev: React.FormEvent<HTMLFormElement>) => {
            ev.preventDefault();
            const formData = new FormData(ev.currentTarget);

            onSearch({
              // farmType: formData.get("farmType"),
              ...(Object.fromEntries(formData.entries()) as searchParamsSchema),
            });
          }}
        >
          <Card>
            <div className="grid grid-cols-12 gap-4">
              <div className="relative col-span-6 tablet:col-span-4">
                <div
                  className="flex px-3 absolute z-10 pointer-events-none transition-all duration-200 inset-x-0 -translate-y-2 text-xs justify-between text-gray-500 dark:text-gray-300"
                  data-cy="label"
                >
                  <label className="px-1 whitespace-nowrap bg-white text-xs">
                    Farm Type
                  </label>
                  <span className="text-primary pr-1 bg-white">*</span>
                </div>

                <div className="rounded-md shadow-sm relative">
                  <select
                    name="farmType"
                    title="Farm Type"
                    className="block w-full tablet:text-sm rounded-md pt-3 pb-3 border-1 focus:ring-4 focus:ring-offset-0  bg-white border-gray-300 focus:border-gray-700 focus:ring-gray-50 "
                    aria-invalid="false"
                    value={farmType}
                    onChange={(e) => setFarmType(e.target.value)}
                  >
                    <option value="">- Select - </option>
                    <option value="PSL">PSL</option>
                    <option value="PSR">PSR</option>
                  </select>
                </div>
              </div>

              <div className="relative col-span-6 tablet:col-span-4">
                <div
                  className="flex px-3 absolute z-10 pointer-events-none transition-all duration-200 inset-x-0 -translate-y-2 text-xs justify-between text-gray-500 dark:text-gray-300"
                  data-cy="label"
                >
                  <label className="px-1 whitespace-nowrap bg-white text-xs">
                    Bird Type
                  </label>
                  <span className="text-primary pr-1 bg-white">*</span>
                </div>

                <div className="rounded-md shadow-sm relative">
                  <select
                    name="birdType"
                    title="Bird Type"
                    className="block w-full tablet:text-sm rounded-md pt-3 pb-3 border-1 focus:ring-4 focus:ring-offset-0  bg-white border-gray-300 focus:border-gray-700 focus:ring-gray-50 "
                    aria-invalid="false"
                    value={birdType}
                    onChange={(e) => setBirdType(e.target.value)}
                  >
                    <option value="">- Select -</option>
                    {birdTypes.map((bird) => (
                      <option key={bird.value} value={bird.value}>
                        {bird.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="relative col-span-6 tablet:col-span-4">
                <div
                  className="flex px-3 absolute z-10 pointer-events-none transition-all duration-200 inset-x-0 -translate-y-2 text-xs justify-between text-gray-500 dark:text-gray-300"
                  data-cy="label"
                >
                  <label className="px-1 whitespace-nowrap bg-white text-xs">
                    Frequency
                  </label>
                  <span className="text-primary pr-1 bg-white">*</span>
                </div>

                <div className="rounded-md shadow-sm relative">
                  <select
                    name="frequency"
                    title="Frequency"
                    className="block w-full tablet:text-sm rounded-md pt-3 pb-3 border-1 focus:ring-4 focus:ring-offset-0  bg-white border-gray-300 focus:border-gray-700 focus:ring-gray-50 "
                    aria-invalid="false"
                    value={frequency}
                    onChange={(e) => setFrequency(e.target.value)}
                  >
                    <option value="">- Select - </option>
                    <option value="W">Week</option>
                    <option value="D">Day</option>
                  </select>
                </div>
              </div>

              <div className="relative col-span-6 tablet:col-span-4">
                <div
                  className="flex px-3 absolute z-10 pointer-events-none transition-all duration-200 inset-x-0 -translate-y-2 text-xs justify-between text-gray-500 dark:text-gray-300"
                  data-cy="label"
                >
                  <label className="px-1 whitespace-nowrap bg-white text-xs">
                    Date From
                  </label>
                  <span className="text-primary pr-1 bg-white">*</span>
                </div>

                <div className="rounded-md shadow-sm relative">
                  <select
                    name="fromDate"
                    title="Date From"
                    className="block w-full tablet:text-sm rounded-md pt-3 pb-3 border-1 focus:ring-4 focus:ring-offset-0 bg-white border-gray-300 focus:border-gray-700 focus:ring-gray-50"
                    aria-invalid="false"
                    onChange={(e) => setFromDate(e.target.value)}
                    value={fromDate}
                  >
                    <option value="">- Select - </option>
                    {farmType &&
                      birdType &&
                      frequency &&
                      fromDates
                        .sort(
                          (a, b) =>
                            parseDate(b.label).getTime() -
                            parseDate(a.label).getTime()
                        )
                        .filter(
                          (d) =>
                            d._filter.toLowerCase() ===
                            `${farmType}_${birdType}_${frequency}`.toLowerCase()
                        )
                        .map((fromDate) => (
                          <option key={fromDate.label} value={fromDate.label}>
                            {fromDate.label}
                          </option>
                        ))}
                  </select>
                </div>
              </div>

              <div className="relative col-span-6 tablet:col-span-4">
                <Button type="submit" theme="primary" className="w-full">
                  Search
                </Button>
              </div>

              <div className="relative col-span-6 tablet:col-span-4">
                <Button
                  type="button"
                  theme="primary"
                  onClick={handleClickNew}
                  disabled={isButtonDisabled}
                  className="w-full"
                >
                  New
                </Button>
              </div>
            </div>
          </Card>
        </form>
      </div>
      <NewStandardsModal
        open={isOpen}
        onOpenChange={setIsOpen}
        onSubmit={callCopyStandards}
      />
    </>
  );
};

export { Search as default };
