// TODO: replace core Input component with this one

import classNames from "classnames";
import React from "react";

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label: string;
  type?: string;
  inputClassName?: string;
  labelClassName?: string;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(({
  label, 
  type, 
  inputClassName, 
  labelClassName, 
  ...props 
}, ref) => {
  return (
    <div className="relative">
      {label && (
        <label
          htmlFor={props.id || props.name} // Make it dynamic based on passed id or name prop
          className={classNames("absolute -top-2 left-2 z-10 inline-block bg-white px-1 text-xs font-medium text-gray-500", labelClassName)}
        >
          {label} {/* Display the label prop here */}
        </label>
      )}
      <input
        {...props}
        ref={ref}
        className={classNames("shadow-sm block w-full rounded-md border-0 py-2.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primary tablet:text-sm tablet:leading-6 disabled:opacity-50 disabled:cursor-not-allowed", inputClassName)}
        type={type || "text"} // Make it dynamic based on passed type prop
      />
    </div>
  );
});

export default Input;
