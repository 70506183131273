import { Alert } from "components/core";
import { type IFarm } from "helpers/farmUtilities";

type FarmSelectSummaryProps = {
  selectedFarms: IFarm[];
};

const FarmSelectSummary: React.FC<FarmSelectSummaryProps> = ({
  selectedFarms,
}) => {
  return (
    <div>
      {!selectedFarms ? (
        <Alert theme="warning">No farms selected!</Alert>
      ) : (
        <div>
          {selectedFarms
            .slice(0, 5)
            .map((f) => `${f.FarmName} (${f.FarmCode})`)
            .join(", ")}
          ...
        </div>
      )}
    </div>
  );
};

export default FarmSelectSummary;
