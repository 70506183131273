import { useState, createContext, useContext, useEffect, useCallback } from "react";
import { useUser } from "hooks/useUser";

const PREFIX = "pfv";

export interface IPersistantFormValuesContextType {
  setPersistantFormValue: (key: string, value: any) => void;
  getPersistantFormValue: (key: string) => any;
  clearPersistantFormValues: (key: string) => void;
}

export interface PersistantFormValuesProviderProps {
  children: React.ReactNode;
}

const PersistantFormValuesContext =
  createContext<IPersistantFormValuesContextType>({
    setPersistantFormValue: () => {},
    getPersistantFormValue: () => {},
    clearPersistantFormValues: () => {},
  });

const fetchFromSessionStorage = () => {
  let parsedValues: Record<string, any> = {};

  // Load from session storage
  const keys = Object.keys(sessionStorage).filter((key) =>
    key.startsWith(`${PREFIX}:`)
  );

  keys.forEach((key) => {
    const sessionValue = sessionStorage.getItem(key);
    if (sessionValue) {
      parsedValues[key] = JSON.parse(sessionValue);
    }
  });

  return parsedValues;
}

export function PersistantFormValuesProvider({
  children,
}: PersistantFormValuesProviderProps) {
  const { isSignedIn } = useUser();

  const [persistantFormValues, setPersistantFormValues] = useState<
    Record<string, any>
  >(fetchFromSessionStorage);

  const setPersistantFormValue = useCallback((key: string, value: any) => {
    setPersistantFormValues((prev) => ({ ...prev, [`${PREFIX}:${key}`]: value }));

    // Save to session storage
    sessionStorage.setItem(`${PREFIX}:${key}`, JSON.stringify(value));
  }, []);

  const getPersistantFormValue = useCallback((key: string) => {
    return persistantFormValues[`${PREFIX}:${key}`];
  }, [persistantFormValues]);

  const clearPersistantFormValues = useCallback((key: string) => {
    setPersistantFormValues((prev) => ({ ...prev, [`${PREFIX}:${key}`]: undefined }));

    // Remove from session storage
    sessionStorage.removeItem(`${PREFIX}:${key}`);
  }, []);

  const clearAllPersistantFormValues = () => {
    setPersistantFormValues({});
    sessionStorage.clear();
  };

  useEffect(() => {
    if (!isSignedIn) {
      clearAllPersistantFormValues();
    }
  }, [isSignedIn]);

  return (
    <PersistantFormValuesContext.Provider
      value={{
        setPersistantFormValue,
        getPersistantFormValue,
        clearPersistantFormValues,
      }}
    >
      {children}
    </PersistantFormValuesContext.Provider>
  );
}

export function usePersistantFormValues() {
  const context = useContext(PersistantFormValuesContext);

  if (context === undefined) {
    throw new Error(
      "usePersistantFormValues must be used within an PersistantFormValuesProvider"
    );
  }

  return context;
}
