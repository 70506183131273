import React, {
  useState,
  createContext,
  useMemo,
} from "react";
import { useUser } from "hooks/useUser";
import { useFarmScheduleGetMany } from "hooks/useFarmSchedule";
import { usePrefetchFarmStandards } from "hooks/useFarmStandard";

export interface ISchedule {
  [key: string]: any;
}

export interface IAppDataContext {
  schedules: ISchedule[];
  pageSubtitle: string;
  setPageSubtitle: (subtitle: string) => void;
  pageTitle: string;
  setPageTitle: (title: string) => void;
}

export const AppDataContext = createContext({} as IAppDataContext);

interface AppDataProviderProps {
  children: React.ReactNode;
}

export function AppDataProvider({ children }: AppDataProviderProps) {
  const { isSignedIn } = useUser();
  
  const { schedules } = useFarmScheduleGetMany({
    enabled: isSignedIn,
  });
  usePrefetchFarmStandards({
    enabled: isSignedIn,
  });

  const [pageSubtitle, setPageSubtitle] = useState<string>("");
  const [pageTitle, setPageTitle] = useState<string>("");

  const contextValue = useMemo(
    () => ({
      schedules,
      pageSubtitle,
      setPageSubtitle,
      pageTitle,
      setPageTitle,
    }),
    [schedules, pageSubtitle, pageTitle]
  );

  return (
    <AppDataContext.Provider
      value={contextValue}
    >
      {children}
    </AppDataContext.Provider>
  );
}

