import classNames from "classnames";
import { Disclosure } from "@headlessui/react";
import { ChevronRightIcon } from "assets/icons";
import { useUser } from "hooks/useUser";
import { Link } from "react-router-dom";

type MenuItemOutput = {
  id: string;
  title: string;
  href: string;
  children?: MenuItemOutput[];
};

type MenuProps = {
  menuName: string;
  onClickMenuItem?: () => void;
};

const menu = {
  menuItems: [
    {
      id: "1",
      title: "User(s)",
      href: "user",
      children: [
        // {
        //   id: "1-1",
        //   title: "Users",
        //   href: "user",
        // },
        // {
        //   id: "1-2",
        //   title: "Roles & Permissions",
        //   href: "user/role",
        // }
      ],
    },
    {
      id: "2",
      title: "Standards",
      href: "standards",
    },
  ],
};

const Menu: React.FC<MenuProps> = ({ menuName, onClickMenuItem }) => {
  const { user } = useUser();

  // function isCurrent(item: (typeof menu.menuItems)[0]): boolean {
  //   if (item.href === window.location.pathname) {
  //     return true;
  //   } else if (item.children && item.children.length > 0) {
  //     return item.children.some((child) => isCurrent(child));
  //   }

  //   return false;
  // }

  const buildMenu = (items: MenuItemOutput[]) => {
    return (
      <ul>
        {items.map((item) => (
          <li key={item.id}>
            {item.children && item.children.length ? (
              <Disclosure as="div" className="group">
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={classNames(
                        // isCurrent(item) ? "bg-gray-50" : "hover:bg-gray-50",
                        "font-semibold flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm leading-6 group-hover:text-primary"
                      )}
                    >
                      <ChevronRightIcon
                        className={classNames(
                          open ? "rotate-90" : "",
                          "h-5 w-5 shrink-0 group-hover:text-primary"
                        )}
                        aria-hidden="true"
                      />
                      {item.title}
                    </Disclosure.Button>
                    <Disclosure.Panel as="ul" className="mt-1 px-2">
                      {buildMenu(item.children as MenuItemOutput[])}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ) : (
              <Link
                to={item.href}
                className={classNames(
                  // isCurrent(item) ? "bg-gray-50" : "hover:bg-gray-50",
                  "font-semibold block rounded-md py-2 pl-10 pr-2 text-sm leading-6 hover:text-primary"
                )}
                onClick={() => {
                  user && saveRecentVisit(user.username, item.id);
                  onClickMenuItem && onClickMenuItem();
                }}
              >
                {item.title}
              </Link>
            )}
          </li>
        ))}
      </ul>
    );
  };

  return <nav>{buildMenu(menu.menuItems)}</nav>;
};

export default Menu;

function saveRecentVisit(userId: string, menuId: string) {
  if (!userId || !menuId) {
    return;
  }

  const recentVisits = localStorage.getItem(`rv-${userId}`);
  const recentVisitsJson = JSON.parse(recentVisits || "[]") as string[];
  const recentVisitsUpdated = recentVisitsJson
    .filter((r: string) => r !== menuId)
    .slice(0, 3);
  recentVisitsUpdated.unshift(menuId);
  localStorage.setItem(`rv-${userId}`, JSON.stringify(recentVisitsUpdated));
}
