import Card from "components/Card";
import { Alert } from "components/core";
import ListSkeleton from "components/core/Lists/ListSkeleton";
import { useUserGetMany } from "hooks/useUser";
import {  useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UserSearch from "./search";

const UserList: React.FC = () => {
  const { isLoading: isLoadingUsers, error: errorUsers, data: users } = useUserGetMany({
    enabled: true,
  });

  const [filteredUser, setFilteredUsers] = useState(users)


  useEffect(() => {
    // Update filteredUser when users data is available
    setFilteredUsers(users || []);
  }, [users]);

  if (isLoadingUsers) {
    return <ListSkeleton />;
  }

  if (errorUsers) {
    return <Alert theme="danger">{errorUsers}. <Link className="underline" to="/">Return to homepage?</Link></Alert>;
  }

  return (
    <Card>
      <div className="">
        <UserSearch users={users} setFilteredUsers={setFilteredUsers} />
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="font-semibold py-1.5 pl-4 pr-3 text-left text-sm text-gray-900 tablet:pl-0"
              >
                Name
              </th>
              <th
                scope="col"
                className="font-semibold hidden px-3 py-1.5 text-left text-sm text-gray-900 tablet:table-cell"
              >
                Email
              </th>
              <th
                scope="col"
                className="font-semibold hidden px-3 py-1.5 text-left text-sm text-gray-900 tablet:table-cell"
              >
                Permission Level
              </th>
              <th
                scope="col"
                className="font-semibold hidden px-3 py-1.5 text-left text-sm text-gray-900 tablet:table-cell"
              >
                Permission Group
              </th>
              <th scope="col" className="relative py-1.5 pl-3 pr-4 tablet:pr-0">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200 bg-white">
          {!!filteredUser.length ? (
              filteredUser.map((user) => (
                <tr key={user.id}>
                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 tablet:w-auto tablet:max-w-none tablet:pl-0">
                    <Link to={`${user.id}`}>
                      <div>{user.fullname}</div>
                      <div className="text-xs text-gray-500">{user.username}</div>
                    </Link>
                    <dl className="font-normal tablet:hidden">
                      <dt className="sr-only">Role</dt>
                      <dd className="mt-1 truncate text-gray-700">
                        {user.permissionLevelId}
                      </dd>
                      <dd className="mt-1 truncate text-gray-700">
                        {user.permissionGroupId}
                      </dd>
                      <dt className="sr-only">Email</dt>
                      <dd className="mt-1 truncate text-gray-500">
                        {user.email}
                      </dd>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 tablet:table-cell">
                    {user.email}
                  </td>
                  <td className="hidden px-3  py-4 text-sm text-gray-500 tablet:table-cell">
                    {user.permissionLevelId}
                  </td>
                  <td className="hidden px-3  py-4 text-sm text-gray-500 tablet:table-cell">
                    {user.permissionGroupId}
                  </td>
                  <td className="py-4 pl-3 pr-4 text-right text-sm font-medium tablet:pr-0">
                    <Link
                      to={`${user.id}`}
                      className="text-primary hover:text-primary-dark"
                    >
                      Edit<span className="sr-only">, {user.fullname}</span>
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="">
                  No users found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

export default UserList;
