import classNames from "classnames";

type CheckboxProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  label: string;
  inputClassName?: string;
  labelClassName?: string;
  labelDescription?: string;
};

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  inputClassName,
  labelClassName,
  labelDescription,
  ...props
}) => {
  return (
    <div className="relative flex items-start">
      <div className="flex h-6 items-center">
        <input
          {...props}
          type="checkbox"
          className={classNames(
            "text-primary focus:ring-primary h-4 w-4 rounded border-gray-300 cursor-pointer",
            inputClassName
          )}
        />
      </div>
      <div className="ml-3 text-sm leading-6">
        <label
          htmlFor={props.id || props.name}
          className={classNames("font-medium text-gray-900", labelClassName)}
        >
          {label}
        </label>
        {labelDescription && (
          <p id="comments-description" className="text-xm text-gray-500">
            {labelDescription}
          </p>
        )}
      </div>
    </div>
  );
};

export default Checkbox;
