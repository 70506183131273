import classNames from "classnames";

type LegendProps = {
  title: string;
  description: string;
  className?: string;
};

const Legend: React.FC<LegendProps> = ({ title, description, className }) => {
  return (
    <legend className={classNames(className)}>
      <h2 className="font-semibold text-base leading-7 text-gray-900">
        {title}
      </h2>
      <p className="mt-0 text-xs leading-6 text-gray-500">{description}</p>
    </legend>
  );
};

export default Legend;
