import React, { useState } from "react";
import { type IFarm } from "helpers/farmUtilities";
import Input from "../input";


type UserSearchProps = {
  farms: IFarm[];
  setFilteredFarms: React.Dispatch<React.SetStateAction<IFarm[]>>;
};

const FarmSearch: React.FC<UserSearchProps> = ({ farms, setFilteredFarms }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (query: string) => {
    const normalizedQuery = query.toLowerCase();
    
    const filteredUsers = farms.filter(
      (farms) =>
      farms.FarmCode.toLowerCase().includes(normalizedQuery) ||
      farms.FarmName.toLowerCase().includes(normalizedQuery)
    );
    setFilteredFarms(filteredUsers);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    handleSearch(event.target.value);
  };

  return (
    <div className="mb-4">
      <label htmlFor="search" className="sr-only">
        Search
      </label>
      <Input
        type="text"
        id="search"
        label="Filter farms"
        className="border p-2"
        placeholder="E.g. Farm 1..."
        value={searchQuery}
        onChange={handleChange}
      />
    </div>
  );
};

export default FarmSearch;
