import { protectedResources } from "authConfig";
import { useCallback, useEffect, useRef, useState } from "react";
import useAuthenticatedFetch from "./useFetchWithMsal";
import { IMediaItem } from "helpers/mediaUtilities";
import { onErrorSchema, onSuccessSchema } from "./useFormData";

type useMediaCreateProps = {
  onSuccess?: onSuccessSchema;
  onError?: onErrorSchema;
};

export const useMediaCreate = ({
  onSuccess,
  onError,
}: useMediaCreateProps = {}) => {
  const { isLoading, error, execute } = useAuthenticatedFetch(
    {
      scopes: protectedResources.api.scopes.write,
    },
    {
      onSuccess,
      onError,
    }
  );

  const mutate = useCallback(
    async (formData: FormData) => {
      const { data } = await execute("POST", "/api/media-post", formData);

      return data?.d ?? [];
    },
    [execute]
  );

  return { isLoading, error, mutate };
};

type useMediaDeleteProps = {
  onSuccess?: onSuccessSchema;
  onError?: onErrorSchema;
};

export const useMediaDelete = ({
  onSuccess,
  onError,
}: useMediaDeleteProps = {}) => {
  const { isLoading, error, execute } = useAuthenticatedFetch(
    {
      scopes: protectedResources.api.scopes.write,
    },
    {
      onSuccess,
      onError,
    }
  );

  const mutate = useCallback(
    async (filesToDelete: string[]) => {
      const { data } = await execute("DELETE", "/api/media-delete", {
        filesToDelete,
      });

      return data?.d ?? [];
    },
    [execute]
  );

  return { isLoading, error, mutate };
};

type useMediaGetOneByIdProps = {
  enabled?: boolean;
  id: string;
};

export const useMediaGetOneById = ({
  enabled = true,
  id,
}: useMediaGetOneByIdProps) => {
  const { error, execute } = useAuthenticatedFetch({
    scopes: protectedResources.api.scopes.read,
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [data, setData] = useState<IMediaItem | null>(null);

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    const { data } = await execute(
      "GET",
      `/api/media-get?id=${id}&limit=&offset=&order=&orderDirection=`
    );

    const newData = (data?.d?.[0] ?? null) as IMediaItem | null;

    setData(newData);

    setIsLoading(false);
    setIsFetched(true);

    return newData;
  }, [execute, id]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, isFetched, error, data };
};

type useMediaGetManyProps = {
  enabled?: boolean;
  params?: Record<string, string>;
  limit?: number; // Optional limit parameter
  orderBy?: string; // Optional orderBy parameter
  orderByDirection?: "asc" | "desc"; // Optional orderByDirection parameter
};

export const useMediaGetMany = ({
  enabled = true,
  params,
  limit = 10, // Set a default limit value
  orderBy = "date", // Default orderBy parameter
  orderByDirection = "asc", // Default orderByDirection
}: useMediaGetManyProps) => {
  const { isLoading, error, execute } = useAuthenticatedFetch({
    scopes: protectedResources.api.scopes.read,
  });

  const [data, setData] = useState<IMediaItem[]>([]);
  const [hasMore, setHasMore] = useState(true); // State to keep track if there are more items to load

  const prevOffset = useRef(0);
  const prevParams = useRef(params);

  const fetchData = useCallback(async ({ 
    offset = 0,
  } : {
    offset?: number;
  } = {
  }) => {
    const paramKeys = Object.keys(params ?? {});

    const { data } = await execute(
      "GET",
      `/api/media-get?limit=${limit}&offset=${offset}&order=${orderBy}&orderDirection=${orderByDirection}${
        paramKeys.length && params
          ? "&" + paramKeys.map((key) => `${key}=${params[key]}`).join("&")
          : ""
      }`
    );

    const fetchedData = data?.d ?? [];

    setData((prevData) => [...prevData, ...fetchedData]);
    setHasMore(fetchedData.length === limit); // If fetched data is less than limit, there's no more data
    prevOffset.current = offset;

    return fetchedData;
  }, [execute, limit, orderBy, orderByDirection, params]);

  const loadMore = useCallback(() => {
    if (hasMore && !isLoading) {
      console.log("inside useMediaGetMany loadMore")
      fetchData({
        offset: prevOffset.current + limit,
      });
    }
  }, [hasMore, isLoading, limit, fetchData]);

  useEffect(() => {
    if (enabled) {
      // if params have changed, reset the data
      if (JSON.stringify(params) !== JSON.stringify(prevParams.current)) {
        setData([]); // Reset data
        setHasMore(true); // Assume there's more data to be fetched
        prevParams.current = params;
      }

      fetchData();
    }
  }, [enabled, fetchData, params]);

  return { isLoading, error, data, loadMore, hasMore };
};
