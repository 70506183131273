import React, { useState, useContext, useEffect } from "react";
import {
  startOfLastXDays,
} from "helpers/dateUtilities";
import { AppDataContext } from "context/AppDataProvider";
import Breadcrumb from "components/Breadcrumb";
import ScheduleItem from "components/Schedule/ScheduleItem";
import ScheduleItemSkeleton from "components/Schedule/ScheduleItemSkeleton";
import useQuery from "hooks/useQuery";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import SectionTitle from "components/SectionTitle";
import FieldSkeleton from "components/core/Forms/FieldSkeleton";
import { useActiveMenu } from "hooks/useActiveMenu";
import { calcProductionFormDataDates, getFarmHouse, getPlacementFromHouse } from "helpers/farmUtilities";
import { useFormDataGetManyByFormType } from "hooks/useFormData";
import { localDateToSQLDate } from "helpers/dateUtilities";
import { useFarmGetMany } from "hooks/useFarm";
import { useFormGetMany } from "hooks/useForm";

export default function Schedule() {
  const { activeMenu } = useActiveMenu();
  const moduleFeatureGroup = activeMenu?.ModuleFeatureGroup;
  const module = activeMenu?.Module;
  const query = useQuery();

  const farmId = query.get("farmId");
  const houseId = query.get("houseId");

  const { schedules } = useContext(AppDataContext);
  const { data: forms } = useFormGetMany();
  const { data: farms } = useFarmGetMany();

  const farm = farms.find((f) =>
    !isNullEmptyOrWhitespace(farmId)
      ? f.FarmCode.toLowerCase() === farmId.toLowerCase()
      : false
  );

  const house = !isNullEmptyOrWhitespace(houseId) && !!farm ? getFarmHouse(farm, parseInt(houseId)) : undefined;

  const placement = !!house ? getPlacementFromHouse(house, 1) : undefined;

  const datePlaced = placement?._DatePlaced?.normalised;

  const { fromDate, toDate, numDaysSincePlacement } = datePlaced
    ? calcProductionFormDataDates(datePlaced)
    : {
        fromDate: undefined,
        toDate: undefined,
        numDaysSincePlacement: undefined,
      };

  const fromDateStr = localDateToSQLDate(fromDate) ?? "";
  const toDateStr = localDateToSQLDate(toDate) ?? "";

  const isFormDatasEnabled =
    !isNullEmptyOrWhitespace(farmId) &&
    !isNullEmptyOrWhitespace(houseId) &&
    !isNullEmptyOrWhitespace(moduleFeatureGroup) &&
    !isNullEmptyOrWhitespace(module) &&
    !isNullEmptyOrWhitespace(fromDateStr) &&
    !isNullEmptyOrWhitespace(toDateStr) &&
    !isNullEmptyOrWhitespace(forms);

  const {
    data,
    isLoading: isFormDatasLoading,
  } = useFormDataGetManyByFormType({
    enabled: isFormDatasEnabled,
    farmId: farmId ?? "",
    houseId: parseInt(houseId ?? "0"),
    formType: moduleFeatureGroup?.toLowerCase() ?? "",
    moduleId: module ?? "",
    fromDate: fromDateStr,
    toDate: toDateStr,
    forms: forms,
  });

  const [schedule, setSchedule] = useState([]);

  // Set production entries
  // useDeepCompareEffect(() => {
  //   if (!farmId || !houseId || !filteredForms?.length) {
  //     setEntries(undefined);
  //     return;
  //   }

  //   const getProductionData = () => {
  //     if (!farmId || !houseId || !fetchFormValues) return;

  //     const { signal } = abortControllerRef.current;

  //     const formIds = filteredForms
  //       .filter((f) => f.HasFormValues)
  //       .map((f) => ({
  //         formId: f.FormName,
  //         formType: f.FormType,
  //         moduleId: f.ModuleName,
  //       }));

  //     fetchFormValues(farmId, houseId, formIds, signal)
  //       .then((result) => {
  //         if (signal.aborted) return;

  //         setEntries(result?.flat() ?? []);
  //       })
  //       .catch((error) => {
  //         if (signal.aborted) return;

  //         console.error(error.message);
  //       });
  //   };

  //   getProductionData();
  // }, [farmId, houseId, fetchFormValues, filteredForms]);

  // Set schedule
  useEffect(() => {
    if (!farmId || !houseId) return;

    setSchedule(
      schedules.filter(
        (schedule) =>
          schedule.FarmCode.toLowerCase() === farmId.toLowerCase() &&
          schedule.HouseNumber.toString() === houseId.toString()
      )
    );
  }, [farmId, houseId, schedules]);



  // Only show 20 days production. Extra day is for previous values
  const datesToRender = numDaysSincePlacement
    ? startOfLastXDays(numDaysSincePlacement - 1)
    : [];

  return (
    <main className="flex-grow overflow-x-hidden">
      <div className="relative z-20 bg-white border-b border-gray-100">
        <Breadcrumb showHome={false} farmRequired={true} houseRequired={true} />
      </div>
      <div className="grid grid-cols-2 gap-4 p-4">
        <div className="col-span-full">
          <SectionTitle>
            {activeMenu?.Title !== undefined ? (
              activeMenu.Title
            ) : (
              <div className="w-1/3">
                <FieldSkeleton />
              </div>
            )}
          </SectionTitle>
        </div>
        <div className="col-span-full space-y-4">
          {isFormDatasLoading || !isFormDatasEnabled ? (
            <SchedulePageSkeleton />
          ) : (
            datesToRender.map((dateString) => (
              <ScheduleItem
                key={dateString}
                date={dateString}
                placement={placement}
                farm={farm}
                entries={data.flat()}
                schedule={schedule}
              />
            ))
          )}
        </div>
      </div>
    </main>
  );
}

// Current unused - may be useful in future release
// /**
//  * Get the data status by comparing the local and network data.
//  * @param {object} localData  - The data object stored locally.
//  * @param {object} networkData  - The data object fetched from network.
//  * @returns {DATA_STATUS} - The data status.
//  */
// export function getDataStatus(localData, networkData) {
//   if (!localData) {
//     // No pending data
//     // console.log(localData, networkData, networkData?.Status ?? DATA_STATUS.INCOMPLETE)
//     return networkData?.Status ?? DATA_STATUS.INCOMPLETE;
//   }

//   const networkTimestamp = !!networkData?.LastModified
//     ? dateFromDotNetDateString(networkData.LastModified, {
//         asDate: true,
//         normalise: false,
//       }).getTime()
//     : null;

//     console.log("networkTimestamp", networkTimestamp, networkData?._LastModified.native)

//   if (!networkTimestamp || localData.timestamp > networkTimestamp) {
//     // Pending request is latest data
//     return DATA_STATUS.PENDING;
//   }

//   // Pending data exists but is not the latest
//   // console.log(networkData, networkData?.Status ?? DATA_STATUS.INCOMPLETE)
//   return networkData?.Status ?? DATA_STATUS.INCOMPLETE;
// }

const SchedulePageSkeleton = () => {
  return (
    <>
      <div className="animate-pulse bg-white px-3 py-2 border border-gray-200 rounded-md">
        <div className="h-4 w-1/4 mb-2 bg-gray-300 rounded" />
        <ScheduleItemSkeleton />
      </div>
      <div className="animate-pulse bg-white px-3 py-2 border border-gray-200 rounded-md">
        <div className="h-4 w-1/4 mb-2 bg-gray-300 rounded" />
        <ScheduleItemSkeleton />
      </div>
      <div className="animate-pulse bg-white px-3 py-2 border border-gray-200 rounded-md">
        <div className="h-4 w-1/4 mb-2 bg-gray-300 rounded" />
        <ScheduleItemSkeleton />
      </div>
      <div className="animate-pulse bg-white px-3 py-2 border border-gray-200 rounded-md">
        <div className="h-4 w-1/4 mb-2 bg-gray-300 rounded" />
        <ScheduleItemSkeleton />
      </div>
      <div className="animate-pulse bg-white px-3 py-2 border border-gray-200 rounded-md">
        <div className="h-4 w-1/4 mb-2 bg-gray-300 rounded" />
        <ScheduleItemSkeleton />
      </div>
      <div className="animate-pulse bg-white px-3 py-2 border border-gray-200 rounded-md">
        <div className="h-4 w-1/4 mb-2 bg-gray-300 rounded" />
        <ScheduleItemSkeleton />
      </div>
      <div className="animate-pulse bg-white px-3 py-2 border border-gray-200 rounded-md">
        <div className="h-4 w-1/4 mb-2 bg-gray-300 rounded" />
        <ScheduleItemSkeleton />
      </div>
    </>
  );
};
