import React, { useState } from "react";
import { IUser } from "helpers/formUtilities";
import Input from "../input";
import { isNullEmptyOrWhitespace } from "helpers/common";

type UserSearchProps = {
  users: IUser[];
  setFilteredUsers: React.Dispatch<React.SetStateAction<IUser[]>>;
};

const UserSearch: React.FC<UserSearchProps> = ({ users, setFilteredUsers }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (query: string) => {
    const normalizedQuery = query.toLowerCase();

    if (isNullEmptyOrWhitespace(query)) {
      return setFilteredUsers(users);
    }

    const filteredUsers = users.filter(
      (user) =>
        user.fullname.toLowerCase().includes(normalizedQuery) ||
        user.email.toLowerCase().includes(normalizedQuery)
    );
    setFilteredUsers(filteredUsers);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    handleSearch(event.target.value);
  };

  return (
    <div className="mb-4">
      <label htmlFor="search" className="sr-only">
        Search
      </label>
      <Input
        type="text"
        id="search"
        label="Filter users"
        className="border p-2"
        placeholder="E.g. John smith..."
        value={searchQuery}
        onChange={handleChange}
      />
    </div>
  );
};

export default UserSearch;
