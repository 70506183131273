import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "helpers/common";

const buttonVariants = cva(
  "inline-flex items-center justify-center whitespace-nowrap rounded-md font-medium ring-offset-white transition-colors focus:outline-none focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
  {
    variants: {
      variant: {
        default:
          "uppercase border border-primary text-primary hover:bg-primary hover:text-white focus:border-primary  focus:ring-4 focus:ring-offset-0 focus:ring-primary focus:ring-opacity-10",
        primary:
          "uppercase shadow-sm uppercase bg-primary bg-gradient-to-r from-primary to-secondary border-primary hover:bg-primary-dark text-white focus:ring-4 focus:ring-offset-0 focus:ring-primary focus:ring-opacity-10 focus:border-primary focus:text-primary-lighter",
        success:
          "uppercase shadow-sm uppercase bg-success-50 bg-gradient-to-r from-success-50 via-success-50 to-success-100 border border-success-400 shadow-sm text-success-600 hover:bg-success-50 focus:border-success-500 focus:text-success-500 focus:ring-4 focus:ring-offset-0 focus:ring-success-500 focus:ring-opacity-10",
        danger:
          "uppercase shadow-sm uppercase bg-danger-50 bg-gradient-to-r from-danger-50 via-danger-50 to-danger-100 border border-danger-400 shadow-sm text-danger-600 hover:bg-danger-50 focus:border-danger-500 focus:text-danger-500 focus:ring-4 focus:ring-offset-0 focus:ring-danger-500 focus:ring-opacity-10",
        warning:
          "uppercase shadow-sm uppercase bg-warning-50 bg-gradient-to-r from-warning-50 via-warning-50 to-warning-100 border border-warning-400 shadow-sm text-warning-600 hover:bg-warning-50 focus:border-warning-500 focus:text-warning-500 focus:ring-4 focus:ring-offset-0 focus:ring-warning-500 focus:ring-opacity-10",
        text: "hover:text-primary",
        gray: "uppercase shadow-sm uppercase bg-gray-100 bg-gradient-to-r from-gray-100 via-gray-200 to-gray-300 border border-gray-400 shadow-sm text-gray-600 hover:bg-gray-100 hover:bg-none focus:border-gray-500 focus:ring-4 focus:ring-offset-0 focus:ring-gray-500 focus:ring-opacity-10",
        link: "text-primary underline-offset-4 hover:underline",
        outline:
          "uppercase shadow-sm uppercase bg-white border border-gray-300 text-gray-600 hover:bg-gray-100 focus:border-gray-500 focus:ring-4 focus:ring-offset-0 focus:ring-gray-500 focus:ring-opacity-10",
      },
      size: {
        default: "h-10 px-4 py-2 text-sm",
        sm: "h-9 rounded-md px-3 text-xs",
        lg: "h-11 rounded-md px-8 text-sm",
        icon: "h-10 w-10",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  }
);
Button.displayName = "Button";

export { Button as default, buttonVariants };
