import { ThumbUpIcon } from "@heroicons/react/outline";
import classNames from "classnames";

const ListEmpty: React.FC<{
  className?: string;
}> = ({ className }) => {
  return (
    <div className={classNames("text-center", className)}>
      <ThumbUpIcon
        className="mx-auto h-12 w-12 text-success-400"
        aria-hidden="true"
      />
      <h3 className="mt-2 font-medium text-gray-500">All data successfully synced</h3>
    </div>
  );
};

export default ListEmpty;
