import Button from "components/placement/Button";
import Calendar from "components/placement/Calendar";
import Modal from "components/placement/Modal";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";
import { DateTime } from "luxon";
import { useState } from "react";

type NewStandardsModalProps = {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  onSubmit: (formData: FormData) => void;
};

export const NewStandardsModal: React.FC<NewStandardsModalProps> = ({
  open,
  onOpenChange,
  onSubmit,
}) => {
  const [date, setDate] = useState("");

  const handleSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
    ev.preventDefault();

    const formData = new FormData(ev.currentTarget);
    // Manually assign value from calendar element to form data "date"
    formData.set("date", date);

    onSubmit(formData);
  };

  const handleChangeDate = (dateIn?: Date) => {
    // console.log("dateIn", dateIn)
    if (!dateIn || isNullEmptyOrWhitespace(dateIn.toString())) {
      setDate("");
      return;
    }

    const dateString = DateTime.fromJSDate(dateIn).toISODate() ?? "";
    setDate(dateString);
  };

  return (
    <Modal open={open} onOpenChange={onOpenChange} title="Date from">
      <form onSubmit={handleSubmit} className="relative">
        {/* <label
          htmlFor="dateUpTo"
          className="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-normal text-gray-500"
        >
          New Date
        </label> */}
        {/* <DatePicker
          defaultOpen={false}
          // open={isDatePickerOpen}
          open={true}
          onOpenChange={(isOpen) => {
            // setIsDatePickerOpen(isOpen);
          }}
          selected={
            !isNullEmptyOrWhitespace(date)
              ? DateTime.fromISO(date as string).toJSDate()
              : undefined
          }
          onSelect={handleChangeDate}
          //buttonClassName="w-full border-gray-300 bg-white text-gray-500 hover:bg-white hover:text-primary py-4"
        /> */}
        <Calendar
          mode="single"
          selected={
            !isNullEmptyOrWhitespace(date)
              ? DateTime.fromISO(date as string).toJSDate()
              : undefined
          }
          onSelect={handleChangeDate}
          // defaultMonth={selected || new Date()}
          // month={selected || new Date()}
        />

        {/* <input
          name="somethingelse"
          type="text"
        /> */}
        <Button variant="primary" type="submit">
          Create
        </Button>
      </form>
    </Modal>
  );
};
