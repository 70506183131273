import UserList from "components/config/user/list";
import { Button } from "components/core";
import { PlusCircleIcon } from "assets/icons";
import PageHeader from "components/PageHeader";
import { useNavigate } from "react-router";
import { isSuperUser, useUser } from "hooks/useUser";

const UserPage = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  return (
    <div className="mt-4 grid grid-cols-2 gap-4">
      <PageHeader className="col-span-full">
        <div className="flex flex-row justify-between">
          <div className="text-xl">User(s)</div>
          {isSuperUser(user) && (
            <div className="">
              <Button
                type="button"
                theme="primary"
                onClick={() => void navigate("create")}
              >
                <PlusCircleIcon className="mr-0.5 h-5 w-5" /> Create New
              </Button>
            </div>
          )}
        </div>
      </PageHeader>
      <div className="col-span-full">
        <UserList />
      </div>
    </div>
  );
};

export default UserPage;
