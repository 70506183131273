import { protectedResources } from "authConfig";
import useAuthenticatedFetch from "./useFetchWithMsal";
import { useCallback, useEffect, useState } from "react";
import { db } from "db";
import { localDate } from "helpers/dateUtilities";
import { DBStandardData } from "db/Standard";

type usePrefetchFarmStandardsProps = {
  enabled?: boolean;
};

export const usePrefetchFarmStandards = ({ enabled = true }: usePrefetchFarmStandardsProps = {}) => {
  const { error, execute } = useAuthenticatedFetch({
    scopes: protectedResources.api.scopes.read,
  });

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    const { data } = await execute("GET", "/api/standards-get");

    // TODO: insert into indexedDB
    try {
      (data?.d ?? []).forEach((record: any) => {
        db.standards.put({
          farmGroup: record.FarmGroup.toLowerCase(),
          birdType: record.BirdType.toLowerCase(),
          birdSex: record.BirdSex.toLowerCase(),
          data: record,
          expires: localDate().getTime() + 86400 * 7 * 1000, // Expires in 1 week
        });
      });
    } catch (error) {
      console.log("usePrefetchFarmStandards error: ", error);
    }

    setIsLoading(false);
  }, [execute]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return {
    isLoading,
    error,
  };
};



type useFarmStandardGetManyProps = {
  enabled?: boolean;
  farmGroup: string;
  birdType: string;
  birdSex: string;
};

const defaultData: DBStandardData[] = [];

export const useFarmStandardGetMany = ({
  enabled = true,
  farmGroup,
  birdType,
  birdSex,
}: useFarmStandardGetManyProps) => {
  const [error, setError] = useState<unknown>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(defaultData);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);

      const response = await db.standards.get([
        farmGroup.toLowerCase(),
        birdType.toLowerCase(),
        birdSex.toLowerCase(),
      ]);

      const data = response?.data ?? defaultData;

      setData(data);
      
      return data;
    } catch (error) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  }, [farmGroup, birdType, birdSex]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return {
    isLoading,
    error,
    data,
  };
};
