import { useState, useEffect, useCallback } from "react";
import useAuthenticatedFetch from "./useFetchWithMsal";
import { protectedResources } from "authConfig";
import { dateToString, localDateFromSQL } from "helpers/dateUtilities";
import { isNullEmptyOrWhitespace } from "helpers/stringUtilities";

type useNcnGetManyProps = {
  enabled?: boolean;
  queryStatus: string;
  queryFieldStaff: string;
  queryFarmCode: string;
  queryAuditDateFrom: string;
  queryAuditDateTo: string;
  queryAuditType: string;
  limit?: number; // Optional limit parameter
  orderBy?: string; // Optional orderBy parameter
  orderByDirection?: "asc" | "desc"; // Optional orderByDirection parameter
};

export const useNcnGetMany = ({
  enabled = true,
  queryStatus,
  queryFieldStaff,
  queryFarmCode,
  queryAuditDateFrom,
  queryAuditDateTo,
  queryAuditType,
  limit = 10, // Set a default limit value
  orderBy = "date", // Default orderBy parameter
  orderByDirection = "asc", // Default orderByDirection
}: useNcnGetManyProps) => {
  const { isLoading, error, execute } = useAuthenticatedFetch({
    scopes: protectedResources.api.scopes.read,
  });

  const [data, setData] = useState<unknown[]>([]);
  const [offset, setOffset] = useState(0); // Offset for pagination
  const [hasMore, setHasMore] = useState(true); // State to keep track if there are more items to load

  const fetchData = useCallback(async () => {
    const { data } = await execute(
      "GET",
      `/api/nonconformances-get?farmId=${queryFarmCode}&audit=${queryAuditType}&fromDate=${queryAuditDateFrom}&toDate=${queryAuditDateTo}&status=${queryStatus}&fieldStaff=${queryFieldStaff}&limit=${limit}&offset=${offset}&order=${orderBy}&orderDirection=${orderByDirection}`
    );

    const fetchedData = data?.d ?? [];

    fetchedData.forEach((record: any) => {
      /* TODO: clean this up and find a better way to handle this. Ideally on the server. */
      if (record.Due) {
        const newDueDate = !isNullEmptyOrWhitespace(record.Due)
          ? localDateFromSQL(record.Due)
          : record.Due;

        if (newDueDate instanceof Date) {
          if (newDueDate.getTime() < new Date().getTime()) {
            record.Due = (
              <div className="text-danger-600">{dateToString(newDueDate)}</div>
            );
          } else {
            record.Due = <div>{dateToString(newDueDate)}</div>;
          }
        }
      }

      if (record.NCNID) {
        record.id = record.NCNID;
        delete record.NCNID;
      }

      // Set key to prevent duplicates
      record.key = `${record.id}-${record.ParentQuestionID}`;
    });

    setData((prevData) => [...prevData, ...fetchedData]);
    setHasMore(fetchedData.length === limit); // If fetched data is less than limit, there's no more data

    return fetchedData;
  }, [
    execute,
    queryAuditDateFrom,
    queryAuditDateTo,
    queryAuditType,
    queryFarmCode,
    queryFieldStaff,
    queryStatus,
    limit,
    offset,
    orderBy,
    orderByDirection,
  ]);

  // Refetch function exposed to the parent component
  const refetch = useCallback(() => {
    setData([]); // Reset data
    setOffset(0); // Reset offset to the initial page
    setHasMore(true); // Assume there's more data to be fetched
    fetchData(); // Fetch data with the initial state
  }, [fetchData]);

  const loadMore = useCallback(() => {
    if (hasMore && !isLoading) {
      setOffset((prevOffset) => prevOffset + limit);
      fetchData();
    }
  }, [hasMore, isLoading, limit, fetchData]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, error, data, loadMore, hasMore, refetch };
};

type useNcnFilterOptionsProps = {
  enabled?: boolean;
};

export const useNcnFilterOptions = ({
  enabled = true,
}: useNcnFilterOptionsProps = {}) => {
  const { isLoading, error, execute } = useAuthenticatedFetch({
    scopes: protectedResources.api.scopes.read,
  });

  const [data, setData] = useState<unknown[]>([]);

  const fetchData = useCallback(async () => {
    const { data } = await execute(
      "GET",
      "/api/overviewfilters-get?formId=nonconformance"
    );

    const fetchedData = data?.d ?? [];

    setData(fetchedData);

    return fetchedData;
  }, [execute]);

  useEffect(() => {
    if (enabled) {
      fetchData();
    }
  }, [enabled, fetchData]);

  return { isLoading, error, data };
};
