import { useParams } from "react-router-dom";
import UserForm from "components/config/user/form";
// import UserProfile from "~/components/config/user/profile";

const UserFormPage = () => {
  const { id } = useParams();

  if (!id) {
    // render an error or a loading state
    return null;
  }

  return (
    <>
      <div className="grid grid-cols-2 gap-4">
        <div className="col-span-full">
          <div className="flex-grow text-lg font-medium uppercase text-gray-600">
            User profile
          </div>
        </div>
        <div className="col-span-full">
          {/* <UserProfile id={id} /> */}
          <UserForm />
        </div>
      </div>
    </>
  );
};

export default UserFormPage;
