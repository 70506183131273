import { protectedResources } from "authConfig";
import { useCallback } from "react";
import useAuthenticatedFetch from "./useFetchWithMsal";
import { onErrorSchema, onSuccessSchema } from "./useFormData";

type useReportDownloadProps = {
  onSuccess?: onSuccessSchema;
  onError?: onErrorSchema;
};

export const useReportDownload = ({
  onSuccess,
  onError,
}: useReportDownloadProps = {}) => {
  const { isLoading, error, execute } = useAuthenticatedFetch(
    {
      scopes: protectedResources.api.scopes.read,
    },
    {
      onSuccess,
      onError,
    }
  );

  const mutate = useCallback(
    async (
      ReportName: string,
      ReportParams: Record<string, any>,
      Format: string
    ) => {
      const { data } = await execute("POST", "/api/runreport-post", {
        ReportName,
        ReportParams,
        Format,
      });

      // convert data to blob
      const url = window.URL.createObjectURL(data);
      const link = document.createElement("a");
      const fileExtension = Format === "pdf" ? ".pdf" : ".xls";
      link.href = url;
      link.download = `${ReportName}_${
        ReportParams !== undefined ? Object.values(ReportParams).join("_") : ""
      }${fileExtension}`;
      link.click();
    },
    [execute]
  );

  return { isLoading, error, mutate };
};
