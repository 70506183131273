import { RedirectRequest } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { b2cPolicies, loginRequest } from "authConfig";
import { useUser } from "hooks/useUser";

const SigninForm: React.FC = () => {
  const { isSignedIn, user } = useUser();
  const { instance, inProgress } = useMsal();

  const handleSignIn = () => {
    const authRequest = {
      ...loginRequest,
    };
    
    instance.loginRedirect(authRequest).catch((error) => {
      if (error.errorCode === "AADB2C90118") {
        // Redirect to password reset policy
        instance.loginRedirect({
          authority: b2cPolicies.authorities.forgotPassword.authority,
        } as RedirectRequest);
      }
      // Handle other errors
    });
  };

  const handleForgotPassword = () => {
    // Redirect to password reset policy
    instance.loginRedirect({
      authority: b2cPolicies.authorities.forgotPassword.authority,
    } as RedirectRequest);
  };

  return (
    <>
      {isSignedIn ? (
        <>
          <div>Hi {user?.fullname ?? "Unknown"}!</div>
          <div>
            <a className="text-primary hover:text-primary-dark" href="/">
              Go to app dashboard
            </a>
          </div>
        </>
      ) : (
        <>
          <div className="flex-grow text-lg font-medium uppercase text-gray-600">
            Sign in to your account
          </div>
          {inProgress === "login" ? (
            <div>Signing in...</div>
          ) : (
            <div className="flex flex-col space-y-4">
              <button
                className="text-primary hover:text-primary-dark text-lg"
                onClick={handleSignIn}
              >
                Sign in
              </button>
              <button
                className="text-primary hover:text-primary-dark text-xs"
                onClick={handleForgotPassword}
              >
                Forgot Password?
              </button>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default SigninForm;
