import {
  CheckCircleIcon,
  PlusCircleIcon,
  ExclamationCircleIcon,
  ClockIcon,
} from "assets/icons";
import { DATA_STATUS } from "constants.js";
import { FORM_DATA_QUEUE_STATUS } from "db/FormDataQueue";
import { dateDiffInDays } from "helpers/dateUtilities";

export default function ListItemIcon({
  dataStatus,
  networkStatus,
  date: endDate,
  dateToday,
  loaded = true,
}) {
  if (!loaded) {
    return (
      <div className="animate-pulse h-10 w-10 mr-2 rounded-full bg-gray-300"></div>
    );
  }

  if (networkStatus === FORM_DATA_QUEUE_STATUS.PENDING) {
    return <PlusCircleIcon className="w-10 h-10 mr-2 text-warning-400" />;
  } else if (networkStatus === FORM_DATA_QUEUE_STATUS.FAILED) {
    return <ExclamationCircleIcon className="w-10 h-10 mr-2 text-danger-500" />;
  } else if (dataStatus === DATA_STATUS.COMPLETE) {
    return <CheckCircleIcon className="w-10 h-10 mr-2 text-success-400" />;
  } else if (dateDiffInDays(endDate, dateToday) >= 1) {
    return <ClockIcon className="w-10 h-10 mr-2 text-danger-300" />;
  } else {
    return <PlusCircleIcon className="w-10 h-10 mr-2 text-gray-300" />;
  }
}
