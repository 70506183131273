import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
import { Navigate, useParams } from "react-router-dom";
import {
  AppDataContext,
  type ISchedule,
  IAppDataContext,
} from "context/AppDataProvider";
import { useUser } from "hooks/useUser";
import {
  calculateBirdAge,
  dateToString,
  localDate,
  localDateFromUnix,
  localDateFromSQL,
  localDateToSQLDate,
  localDateToSQL,
  isUKDateString,
  dateFromUKDateString,
} from "helpers/dateUtilities";
import {
  parseValidationString,
  convertStringToRegex,
  isNullEmptyOrWhitespace,
  isNumeric,
} from "helpers/stringUtilities";
import { DATA_STATUS } from "constants.js";
import Production from "components/forms/Production";
import Schedule from "components/forms/Schedule";
import Generic from "components/forms/Generic";
import GenericNonConformance from "components/forms/GenericNonConformance";
import { v4 as uuid } from "uuid";
import useQuery from "hooks/useQuery";
import {
  buildFormValues,
} from "helpers/formsUtilities";
import useDeepCompareEffect from "use-deep-compare-effect";
import FieldsetSkeleton from "components/core/Forms/FieldsetSkeleton";
import {
  IFileUploadValue,
  IFormData,
  IFormField,
  IFormValue,
  IFormValueDataSources,
  getPenDataFromFormData,
  hasPendingFileSubmission,
  INetworkFormData,
  IPenData,
  IPenFormValid,
  getFormDataStatus,
  hasFormValueChanged,
} from "helpers/formUtilities";
import { Alert } from "components/core";
import { deepClone } from "helpers/dataUtilities";
import ListItemStatus from "components/Schedule/ListItemStatus";
import { calcGenericFormDataDates, calcProductionFormDataDates, getFarmHouse, getPlacementFromHouse } from "helpers/farmUtilities";
import { IForm } from "helpers/formUtilities";
import { AppVersion } from "App";
import { useActiveMenu } from "hooks/useActiveMenu";
import { tryInvalidateFormDataCache, useFormDataUpdateOrCreate } from "hooks/useFormData";
import { useFarmStandardGetMany } from "hooks/useFarmStandard";
import DynamicFormTerms from "./DynamicFormTerms";
import { buildFormPageUrl, buildListPageUrl } from "helpers/redirectUtilities";
import { useFormGetMany } from "hooks/useForm";
import { useFarmGetOne } from "hooks/useFarm";
import { toast } from "react-toastify";
import { type IMenuItem } from "components/Sidebar";
import { unstable_usePrompt as usePrompt } from "react-router-dom";
import { getRecordPWAIDFromPenValues, useDynamicFormData } from "hooks/useDynamicFormData";

interface IDynamicFormProps {
  setPageTitle: (pageTitle: string | React.ReactNode) => void;
  setPageSubtitle: (pageSubtitle: string | React.ReactNode) => void;
}



const getFormComponent = (formType: string, formName: string) => {
  if (isNullEmptyOrWhitespace(formType) || isNullEmptyOrWhitespace(formName)) {
    return null;
  }

  const formTypeLower = formType?.toLowerCase();
  const formNameLower = formName?.toLowerCase();

  if (
    formTypeLower === "schedule" ||
    formNameLower === "swab" ||
    formNameLower === "vaccine" ||
    formNameLower === "task"
  ) {
    return Schedule;
  }

  if (
    formTypeLower === "production" || 
    formNameLower === "weeklyproduction" || 
    formNameLower === "monthlyproduction"
  ) {
    return Production;
  }

  if (
    formTypeLower === "nonconformance" ||
    (formTypeLower === "audit" && formNameLower?.startsWith("ncn_"))
  ) {
    return GenericNonConformance;
  }

  return Generic;
};

function getFormDates(formId: string, datePlaced: Date | undefined) {
  if (
    !isNullEmptyOrWhitespace(formId) &&
    ["production", "weeklyproduction", "swab", "vaccine", "task"].includes(
      formId.toLowerCase()
    ) &&
    !isNullEmptyOrWhitespace(datePlaced)
  ) {
    return calcProductionFormDataDates(datePlaced);
  } else if (
    !isNullEmptyOrWhitespace(formId) &&
    !["production", "weeklyproduction", "swab", "vaccine", "task"].includes(
      formId.toLowerCase()
    )
  ) {
    return calcGenericFormDataDates();
  }

  return {
    fromDate: undefined,
    toDate: undefined,
  };
}

export default function DynamicForm({
  setPageTitle,
  setPageSubtitle,
}: IDynamicFormProps) {
  const { activeMenu } = useActiveMenu();

  const moduleFeatureGroup = activeMenu?.ModuleFeatureGroup;
  const module = activeMenu?.Module;

  const { id, view, formId, parentId, parentFormId } = useParams();

  const query = useQuery();
  const farmId = query.get("farmId") ?? "";
  const houseId = query.get("houseId") ?? "";
  const timestamp = getTimestamp();

  const { schedules } =
    useContext<IAppDataContext>(AppDataContext);
  const { isLoading: isLoadingForms, data: forms } = useFormGetMany();
  const { user } = useUser();

  const farm = useFarmGetOne(farmId ?? "");

  const house = !isNullEmptyOrWhitespace(houseId) && !!farm ? getFarmHouse(farm!, parseInt(houseId!)) : undefined;

  const placement = !!house ? getPlacementFromHouse(house, 1) : undefined;

  const datePlaced = placement?._DatePlaced?.normalised;

  const form = useMemo(() => {
    if (formId === undefined) {

      return undefined;
    }

    const _form = (forms as IForm[]).find(
      (f) =>
        f.FormName.toLowerCase() === formId.toLowerCase() &&
        f.FormType?.toLowerCase() === moduleFeatureGroup?.toLowerCase() &&
        f.ModuleName.toLowerCase() === module?.toLowerCase()
    );
    if (_form === undefined) return undefined;

    const _newForm = deepClone(_form) as IForm;

    // Filter form fields by FarmType
    if (farm?.FarmType) {
      _newForm.FormFields = _newForm.FormFields?.filter(
        (ff) =>
          // Filter form fields by FarmType
          isNullEmptyOrWhitespace(ff.FarmType) ||
          ff.FarmType?.split(",").some(
            (ft) => ft.toLowerCase() === farm.FarmType.toLowerCase()
          )
      );
    }
    // Filter form fields by FarmGroup
    if (farm?.FarmGroup) {
      _newForm.FormFields = _newForm.FormFields?.filter(
        (ff) =>
          isNullEmptyOrWhitespace(ff.FarmGroup) ||
          ff.FarmGroup?.split(",").some(
            (fg) => fg.toLowerCase() === farm.FarmGroup.toLowerCase()
          )
      );
    }

    _newForm.FormFields.sort((a, b) => {
      // Sort by position
      const positionDiff = a.Position - b.Position;
      if (positionDiff) {
        return positionDiff;
      }

      // Name
      return a.Name < b.Name ? -1 : 1;
    });

    return _newForm;
  }, [
    farm?.FarmType,
    farm?.FarmGroup,
    formId,
    moduleFeatureGroup,
    forms,
    module,
  ]);

  const parentForm = useMemo(() => {
    const _form = forms.find(
      (f) =>
        f.FormName.toLowerCase() === parentFormId?.toLowerCase() &&
        f.FormType.toLowerCase() === moduleFeatureGroup?.toLowerCase() &&
        f.ModuleName.toLowerCase() === module?.toLowerCase()
    );
    if (_form === undefined) return undefined;

    const _newForm = deepClone(_form) as IForm;

    if (_newForm !== undefined) {
      _newForm.FormFields?.forEach((ff) => {
        ff.Readonly = true;
        // Prevent triggering calculation & default value on parent form
        ff.Calculation = null;
        ff.DefaultValue = null;
      });

      // Filter parent form fields by FarmType
      if (farm?.FarmType) {
        _newForm.FormFields = _newForm.FormFields?.filter(
          (ff) =>
            isNullEmptyOrWhitespace(ff.FarmType) ||
            ff.FarmType?.split(",").some(
              (ft) => ft.toLowerCase() === farm.FarmType.toLowerCase()
            )
        );
      }
    }

    return _newForm;
  }, [farm?.FarmType, moduleFeatureGroup, forms, parentFormId, module]);

  const { fromDate, toDate } = getFormDates(formId ?? "", datePlaced);

  const shouldFetchById = navigator.onLine &&
    formId?.toLowerCase().startsWith("ncn_") &&
    moduleFeatureGroup?.toLowerCase() === "audit" &&
    !isNullEmptyOrWhitespace(id) ? true : false;

  const {
    data: formData,
    previousData: previousFormData,
    isLoading: isLoadingFormData,
    isFetched: isFetchedFormData,
    error: errorFormData,
  } = useDynamicFormData({
    formId,
    formType: moduleFeatureGroup,
    id,
    farmId,
    houseId,
    module,
    fromDate,
    toDate,
    shouldFetchById,
  });

  const birdsAlive = useMemo(() => {
    return formData?.PenValues?.reduce(
      (acc: any, pen: any) => (acc += pen?.BirdsAlive?.BirdsAlive ?? 0),
      0
    );
  }, [formData?.PenValues]);

  // Get parent form data
  const hasParentForm = !isNullEmptyOrWhitespace(parentFormId);
  const {
    data: parentFormData,
    // previousData: previousParentFormData,
    isLoading: isLoadingParentFormData,
    isFetched: isFetchedParentFormData,
    error: errorParentFormData,
  } = useDynamicFormData({
    enabled: hasParentForm,
    formId: parentFormId,
    formType: moduleFeatureGroup,
    id: parentId,
    farmId,
    houseId,
    module,
    fromDate,
    toDate,
    shouldFetchById,
  });

  const {
    isLoading: isLoadingStandards,
    error: errorStandards,
    data: standards,
  } = useFarmStandardGetMany({
    enabled:
      !isNullEmptyOrWhitespace(farm?.FarmGroup) &&
      !isNullEmptyOrWhitespace(placement?.BirdType) &&
      !isNullEmptyOrWhitespace(placement?.BirdSex),
    farmGroup: farm?.FarmGroup ?? "",
    birdType: placement?.BirdType ?? "",
    birdSex: placement?.BirdSex ?? "",
  });

  const [isUnsavedFormDataReady, setIsUnsavedFormDataReady] = useState<boolean>(
    false
  );
  const [unsavedFormData, setUnsavedFormData] = useState<IFormData | undefined>(
    undefined
  );
  const [formValid, setFormValid] = useState<IPenFormValid[]>([]);
  const [requiredFormFieldCount, setRequiredFormFieldCount] = useState<
    number | undefined
  >(undefined);
  const [requiredFieldCompletedCount, setRequiredFieldCompletedCount] =
    useState<number | undefined>(undefined);    
  const [isDataChanged, setIsDataChanged] = useState<boolean>(false);
  usePrompt({ when: isDataChanged, message: 'You have unsaved changes. Are you sure you want to leave?' });

  const [redirectUrl, setRedirectUrl] = useState<string>("");
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

  const localID = useRef<string>("");

  const birdAge =
    placement?._HatchDate?.normalised && house
      ? calculateBirdAge(
          placement._HatchDate.normalised,
          0,
          localDateFromUnix(timestamp) ?? null
        )
      : undefined;

  const dataStatus = getFormDataStatus(formValid);

  const formName = form?.FormName;
  const schedule = useMemo(() => {
    if (!id || !schedules?.length || !formName) return;

    // Only return schedule if formName is "vaccine", "swab" or "task"
    if (!["vaccine", "swab", "task"].includes(formName.toLowerCase())) return;

    return schedules.find((s: ISchedule) => {
      return s.FarmScheduleID.toString() === id.toString();
    });
  }, [id, schedules, formName]);

  const {
    mutate: mutateFormData,
  } = useFormDataUpdateOrCreate({
    onSuccess: async (_response, _data, variables) => {
      // IMPORTANT: don't reference state variables here as they will be stale
      await tryInvalidateFormDataCache(`${window.location.origin}/api/formvaluesbytype-get?formType=${moduleFeatureGroup}&farmId=${farmId}&moduleId=${module}`)

      if (variables.dataStatus === DATA_STATUS.DRAFT) {
        toast.success("Draft saved successfully.");
      } else {
        toast.success("Save successful.");
      }

      /**
      * Allow user to navigate away from page
      */
      setIsDataChanged(false);

      if (variables.successRedirectUrl) {
        setRedirectUrl(variables.successRedirectUrl);
        return;
      } else {
        /**
         * Move pending pen values to saved pen values to avoid uploading again
         * should only be done when form is successfully saved
         */
        const newFormValues = deepClone(variables.unsavedFormData);
        movePendingFilesToSavedFiles(newFormValues.PenValues);
        setUnsavedFormData(newFormValues); // Update form values to persist data changes
      }
    },
    onError: (errMessage, variables) => {
      // IMPORTANT: don't reference state variables here as they will be stale

      if (!navigator.onLine) {
        if (variables.dataStatus === DATA_STATUS.DRAFT) {
          // Draft saved
          toast.warning("You are currently offline. Your draft submission has been saved and will automatically sync once you are back online.");
        } else {
          // Error - likely an offline post that will be handled by background sync...
          // ...lets not show any error to user and just log to console.
          // Success - Pending Sync
          toast.warning("You are currently offline. Your submission has been saved and will automatically sync once you are back online.");
        }

        /**
         * Allow user to navigate away from page
         */
        setIsDataChanged(false);

        if (variables.successRedirectUrl) {
          setRedirectUrl(variables.successRedirectUrl);
          return;
        } else {
          /**
           * Move pending pen values to saved pen values to avoid uploading again
           * should only be done when form is successfully saved
           */
          const newFormValues = deepClone(variables.unsavedFormData);
          movePendingFilesToSavedFiles(newFormValues.PenValues);
          setUnsavedFormData(newFormValues);
        }
      } else {
        toast.error(errMessage);
        console.error(errMessage);
      }
    },
  });

  //#region Callbacks

  const getRedirectUrl = (formId: string, activeMenu: IMenuItem) => {
    if (dataStatus === DATA_STATUS.DRAFT && form?.FormType?.toLowerCase() === "audit") {
      // Draft audit form
      const recordId = getDBRecordId();
      return buildFormPageUrl(
        activeMenu,
        formId,
        parentId,
        parentFormId,
        recordId ?? localID.current
      );
    } 

    return buildListPageUrl(activeMenu);
  };

  const handleFormSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
    // Prevent submission when dataStatus is NOT complete or draft
    if (
      !(
        dataStatus === DATA_STATUS.COMPLETE || dataStatus === DATA_STATUS.DRAFT
      ) ||
      formId === undefined ||
      moduleFeatureGroup === undefined ||
      module === undefined
    ) {
      toast.error(
        "An error occurred while saving the production entry: Invalid form data status. Please complete the form and retry."
      );

      return;
    }

    if (!activeMenu) {
      toast.error(
        "An error occurred while saving the production entry: Invalid menu item."
      );

      return;
    }

    const filteredFormValues: IFormData = deepClone(unsavedFormData);
    // remove empty and NaN values from form values
    filteredFormValues.PenValues.forEach((pen) => {
      pen.Values = pen.Values.filter(
        (value) =>
          !isNullEmptyOrWhitespace(value.Value) &&
          value.Value?.toString() !== "NaN"
      );
    });

    // Set PWAID
    const existingPWAID = getRecordPWAIDFromPenValues(filteredFormValues);
    if (!existingPWAID) {
      if (isNullEmptyOrWhitespace(localID.current)) {
        // Generate unique ID for local DB 
        localID.current = uuid();
      } else {
        // Use existing local ID
      }
    } else {
      localID.current = existingPWAID;
    }
    setCurrentRecordPWAIDIfNotExist(
      filteredFormValues.PenValues,
      localID.current
    );

    // Set Parent PWAID
    const ParentPWAID = !isNullEmptyOrWhitespace(parentFormData)
      ? getRecordPWAIDFromPenValues(parentFormData!)
      : undefined;

    setCurrentRecordParentPWAIDIfNotExist(
      filteredFormValues.PenValues,
      ParentPWAID
    );

    // Prepare form data
    const dateToday = localDate();

    const networkFormData = prepareNetworkFormData(dateToday, { ParentPWAID });

    const formData = new FormData();

    async function appendPendingFilesToFormData() {
      const formName = networkFormData.FormName ?? "unknown";

      for (const pen of filteredFormValues.PenValues) {
        const valuesArray = pen.Values;
        for (const record of valuesArray) {
          if (hasPendingFileSubmission(record.Value)) {
            const fileUploadValue = record.Value as IFileUploadValue;
            const pendingFiles = fileUploadValue.pending;
            const fileUrls: string[] = [];

            for (const file of pendingFiles as File[]) {
              const fileExt = file.name.split(".").pop();
              const fileID = uuid();
              const newFileName = `${fileID}.${fileExt}`;
              const group = (record.QuestionGroup ?? "-").toLowerCase();
              const ref = record.Ref.toLowerCase();
              const newFilePath = `${formName}/${localID.current}/${pen.Pen}/${group}/${ref}/${newFileName}`;
              formData.append("files", file, newFilePath);
              fileUrls.push(newFilePath);
            }

            // Replace pending files with file urls
            // These will then be replaced on the server with the correct file url
            fileUploadValue.pending = fileUrls as any;
          }
        }
      }
    }
    await appendPendingFilesToFormData();

    // Append data
    const blob = new Blob([JSON.stringify(networkFormData)], {
      type: "application/json",
    });

    formData.append("data", blob);

    const successRedirectUrl = getRedirectUrl(formId, activeMenu);

    await mutateFormData(formData, module, { unsavedFormData, successRedirectUrl, dataStatus });

    function prepareNetworkFormData(
      currentDate: Date,
      { ParentPWAID }: { ParentPWAID?: string }
    ): INetworkFormData {
      if (farm === undefined) {
        throw new Error("Farm is undefined");
      }
      if (form === undefined) {
        throw new Error("Form is undefined");
      }
      if (house === undefined) {
        throw new Error("House is undefined");
      }

      const DateApplies = getFormDate();
      if (isNullEmptyOrWhitespace(DateApplies?.toString())) {
        // If we reached here the date was not found, throw error as DateApplies is required
        throw new Error("Form date not found.");
      }

      const recordId = getDBRecordId();
      const LastModified = localDateToSQL(currentDate, {
        includeOffset: false,
        convertTimezoneToServer: true,
      });

      return {
        FarmCode: farm.FarmCode.toLowerCase(),
        FormName: form.FormName.toLowerCase(),
        FormType: form.FormType?.toLowerCase(),
        PWAID: localID.current,
        AppVersion, // e.g. "1.0.0"
        Data: {
          ID: recordId, // null
          House: house!.HouseNumber, // 1
          DateApplies: localDateToSQLDate(DateApplies) ?? "", // Convert to SQL to avoid any server timezone issues // Audit Date?
          LastModified: LastModified ?? "",
          Status: dataStatus,
          PenValues: filteredFormValues.PenValues,
          ParentPWAID,
          FarmCode: farm.FarmCode.toLowerCase(),
          FormName: form.FormName.toLowerCase(),
          FormType: form.FormType?.toLowerCase(),
          AuditStatus: filteredFormValues.AuditStatus
        },
      };
    }
  };

  const setFieldValue = (
    field: IFormField,
    penId: string,
    formValue: IFormValue
  ) => {
    if (!field?.Ref || !penId)
      return new Error("'ref' and 'pen' are required properties.");

    setUnsavedFormData((prevState) => {
      const dataSources: IFormValueDataSources = {
        this: prevState ?? {},
        previous: previousFormData ?? {},
        _standards: standards,
        _farm: farm,
        _user: user,
        _schedule: schedule,
      };

      if (!isNullEmptyOrWhitespace(parentFormData)) {
        dataSources.parent = parentFormData;
      }

      try {
        const newState = buildFormValues(
          field,
          penId,
          formValue,
          form,
          dataSources,
          farm,
          house,
          birdAge?.days,
          getFormDate()
        );

        /**
         * Prevent user from navigating away from page
         * TODO: feature disabled for now
         * See: https://app.clickup.com/t/862ja28ht
         * Uncomment to enable feature
         */
        // if (!isEqual(prevState, newState)) {
        //   setFormDataChanged(true);
        // }

        const prevValue = prevState?.PenValues?.find((pv) => pv.Pen === penId)
            ?.Values?.find((v) => v.Ref === field.Ref);
        if (hasFormValueChanged(formValue.Value, prevValue?.Value, field)) {
          setIsDataChanged(true);
        }

        return newState;
      } catch (error) {
        console.error(error);
      }

      return prevState as any;
    });
  };

  const setFieldValid = (
    fieldId: string, // We use the id here because the field.Ref is not unique. (e.g. when using repeater fields `field.Ref_1`)
    field: IFormField,
    penNumber: string,
    valid: boolean,
    complete: boolean,
    required: boolean
  ) => {
    setFormValid((prevState) => {
      const newFormValid = prevState.filter(
        (fv) =>
          !(
            (
              fv.Ref === fieldId &&
              (isNullEmptyOrWhitespace(field.QuestionGroup) ||
                fv.QuestionGroup === field.QuestionGroup) &&
              fv.Pen.toString() === penNumber.toString()
            )
            // && (isNullEmptyOrWhitespace(field.RepeaterID) || fv.RepeaterID === field.RepeaterID)
          )
      );
      newFormValid.push({
        Ref: fieldId, // We use the id here because the field.Ref is not unique. (e.g. when using repeater fields `field.Ref_1`)
        QuestionGroup: field.QuestionGroup,
        // RepeaterID: field.RepeaterID,
        Pen: penNumber,
        Valid: valid,
        Complete: complete,
        Required: required,
      });

      return newFormValid;
    });
  };

  const removeFieldValid = (
    fieldId: string, // We use the id here because the field.Ref is not unique. (e.g. when using repeater fields `field.Ref_1`)
    field: IFormField,
    penNumber: string
  ) => {
    setFormValid((prevState) => {
      return prevState.filter(
        (fv) =>
          !(
            (
              fv.Ref === fieldId &&
              (isNullEmptyOrWhitespace(field.QuestionGroup) ||
                fv.QuestionGroup === field.QuestionGroup) &&
              fv.Pen.toString() === penNumber.toString()
            )
            // && (isNullEmptyOrWhitespace(field.RepeaterID) || fv.RepeaterID === field.RepeaterID)
          )
      );
    });
  };

  const validateField = (field: IFormField, value: IFormValue["Value"]) => {
    if (isNullEmptyOrWhitespace(value)) return;

    value = !!value ? Number(value) : value;

    if (
      typeof value === "number" &&
      field.MinTol !== 0 &&
      field.MaxTol !== 0 && // MinTol = 0 && MaxTol = 0 should be treated as null values
      // TODO: fix the casting on these values
      (value < field.MinTol || value > field.MaxTol)
    )
      return `${field.Name} must be between ${field.MinTol} and ${field.MaxTol}`;

    if (field.Validation) {
      const aValidation = parseValidationString(field.Validation);
      for (const validation of aValidation) {
        if (validation.function === "regex") {
          const regex = convertStringToRegex(validation.arguments);
          const isValid = regex.test(value?.toString());

          if (!isValid) {
            return `${field.Name} ${validation.message}` || "Invalid value";
          }
        } else if (validation.function === "between") {
          value = Number(value);
          const validationArgs: (string | number)[] =
            validation.arguments.split(",");
          let numericArgs: number[] = [];
          if (validationArgs.length !== 2)
            throw new Error(
              "Between validation function expects two arguements."
            );

          // Between function expects number arguments
          for (let index = 0; index < validationArgs.length; index++) {
            numericArgs[index] = Number(validationArgs[index]);
          }

          const isValid = value >= numericArgs[0] && value <= numericArgs[1];
          if (!isValid) {
            return `${field.Name} ${validation.message}`;
          }
        }
      }
    }

    return;
  };


  /**
   * Set form values
   */
  useEffect(() => {
    if (isFetchedFormData) {
      setIsUnsavedFormDataReady(true);
      setUnsavedFormData(formData);
    }
  }, [isFetchedFormData, formData]);

  /**
   * Data has changed?
   */
  useEffect(
    () => {
      const handleBeforeUnload = (e: BeforeUnloadEvent) => {
        if (isDataChanged) {
          e.preventDefault();
          const message =
            "Data may have changed on the page. Are you sure you wish to navigate away?";
          e.returnValue = message; // Standard for most browsers
          return message; // For some older browsers
        }
      };

      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDataChanged]
  );

  /**
   * Set page title
   */
  useEffect(() => {
    const pageTitle =
      form?.FormTitle !== undefined ? form?.FormTitle : undefined;
    setPageTitle(pageTitle);
  }, [form?.FormTitle, setPageTitle]);

  /**
   * Set form completion count
   */
  useDeepCompareEffect(() => {
    if (!form?.FormFields?.length || !formValid?.length) return;

    let _requiredFieldCount = 0;
    let _requiredFieldCompleteCount = 0;
    for (const fv of formValid) {
      // Total
      if (fv.Required) _requiredFieldCount += 1;
      // Completed
      if (fv.Required && fv.Complete) _requiredFieldCompleteCount += 1;
    }

    setRequiredFormFieldCount(_requiredFieldCount);
    setRequiredFieldCompletedCount(_requiredFieldCompleteCount);
  }, [form?.FormFields, formValid]);

  /**
   * Set page subtitle
   * TODO: move this up to the parent component
   */
  useDeepCompareEffect(() => {
    const result = [];
    const formDate = getFormDate();
    if (!isNullEmptyOrWhitespace(formDate)) {
      result.push(
        <div className="flex space-x-2">
          <div className="text-gray-500">Form Date:</div>
          <div>
            {dateToString(formDate!, {
              includeTime: false,
            })}
          </div>
        </div>
      );
    }

    if (unsavedFormData?.Status !== undefined) {
      result.push(
        <div className="flex space-x-2">
          <div className="text-gray-500">Status:</div>
          <ListItemStatus
            key="statusValue"
            dataStatus={unsavedFormData.Status}
            networkStatus={unsavedFormData._SendStatus}
            date={formDate}
            dateToday={localDate()}
          />
        </div>
      );
    }

    if (unsavedFormData?._LastModified) {
      result.push(
        <div className="flex space-x-2">
          <div className="text-gray-500">Updated:</div>
          <div>
            {dateToString(unsavedFormData?._LastModified.localised, {
              includeTime: true,
            })}
          </div>
        </div>
      );
    }

    setPageSubtitle(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setPageSubtitle,
    timestamp,
    unsavedFormData?.Status,
    unsavedFormData?._LastModified,
  ]);



  function movePendingFilesToSavedFiles(penValues: IPenData[]) {
    penValues.forEach((pen) => {
      pen.Values.forEach((formValue) => {
        if (hasPendingFileSubmission(formValue.Value)) {
          // We can safely assume form value is of type FileUploadValue here
          const fileUploadValue = formValue.Value as IFileUploadValue;
          for (const pendingFile of fileUploadValue.pending as any[]) {
            if (fileUploadValue?.saved === undefined) {
              fileUploadValue.saved = [];
            }

            if (pendingFile instanceof File) {
              fileUploadValue.saved.push(URL.createObjectURL(pendingFile));
            } else {
              fileUploadValue.saved.push(URL.createObjectURL(pendingFile));
            }
          }
          fileUploadValue.pending = [];
        }
      });
    });
  }

  function setCurrentRecordPWAIDIfNotExist(
    formValues: IFormData["PenValues"],
    localID: string
  ) {
    formValues.forEach((p) => {
      if (p.Values.some((pv) => pv.Ref.toLowerCase() === "pwaid") === false) {
        // Pen doesn't have PWAID
        // Lets add it
        p.Values.push({ Ref: "PWAID", Value: localID });
      }
    });
  }

  function setCurrentRecordParentPWAIDIfNotExist(
    formValues: IFormData["PenValues"],
    parentPWAID: string | undefined
  ) {
    if (isNullEmptyOrWhitespace(parentPWAID)) return;

    formValues.forEach((p) => {
      if (
        p.Values.some((pv) => pv.Ref.toLowerCase() === "parentpwaid") === false
      ) {
        // Pen doesn't have PWAID
        // Lets add it
        p.Values.push({ Ref: "parentpwaid", Value: parentPWAID! });
      }
    });
  }

  /**
   * Get the form date.
   * **Production** forms simply use the date URL param.
   * **Schedule** forms use the user selected CompletedDate form value.
   * **Generic** forms use the user selected DateApplies form value.
   * @returns {Date|null}  Returns a local form date.
   */
  function getFormDate() {
    if (formId === undefined) return null;

    // If timestamp is set, use it
    if (!isNullEmptyOrWhitespace(timestamp)) {
      return localDateFromUnix(timestamp);
    }

    if (formId.toLowerCase().startsWith("ncn_")) {
      const dateApplies = parentFormData?.DateApplies;
      if (!isNullEmptyOrWhitespace(dateApplies)) {
        if (isUKDateString(dateApplies)) {
          return dateFromUKDateString(dateApplies!, { asDate: true });
        }

        return localDateFromSQL(dateApplies);
      }
    }

    const pen1 = getPenDataFromFormData("1", unsavedFormData);
    if (!isNullEmptyOrWhitespace(pen1?.Values)) {
      // Pen1 form values exist
      // If 'DateApplies' exists in form values Ref use it... else...
      const dateApplies = pen1?.Values?.find(
        (v) =>
          v.Ref.toLowerCase() === "dateapplies" ||
          v.Ref.toLowerCase() === "completeddate"
      )?.Value;
      if (!isNullEmptyOrWhitespace(dateApplies)) {
        if (isUKDateString(dateApplies)) {
          return dateFromUKDateString(dateApplies! as string, { asDate: true });
        }

        return localDateFromSQL(dateApplies);
      }
    }

    return null;
  }

  /**
   * Get record ID from URL query string
   * @returns {String}
   */
  function getDBRecordId(): IFormData["ID"] {
    if (id === undefined) return null;
    if (id.toString().includes("-")) return null; // Avoid setting PWAID (uuid) as id
    if (isNumeric(id) && id.length === 13) return null; // Avoid setting epoch as id
    return id;
  }

  /**
   * Get timestamp from URL query string
   * @returns {Number}  Epoch
   */
  function getTimestamp() {
    if (id === undefined) return;

    if (!isNumeric(id) || id.length !== 13) return; // Not epoch
    return parseInt(id);
  }

  function handleAcceptTerms() {
    setTermsAccepted(true);
  }

  const FormComponent = getFormComponent(form?.FormType ?? "", form?.FormName ?? "");

  if (!isNullEmptyOrWhitespace(redirectUrl)) {
    return <Navigate to={redirectUrl} />;
  }

  if (
    !isFetchedFormData ||
    isLoadingFormData ||
    (hasParentForm && (!isFetchedParentFormData || isLoadingParentFormData)) ||
    isLoadingStandards ||
    isLoadingForms ||
    !isUnsavedFormDataReady
  ) {
    return (
      <div className="mt-4 grid grid-cols-2 gap-4">
        <div className="col-span-full">
          <FieldsetSkeleton />
        </div>
      </div>
    );
  }

  if (
    // errorSchedule ||
    errorFormData ||
    errorParentFormData ||
    errorStandards
  ) {
    return (
      <Alert theme="danger">
        An error has occurred while loading the form. Refresh and try again. If
        the issue persists please contact our team.
      </Alert>
    );
  }

  if (isNullEmptyOrWhitespace(form)) {
    return (
      <Alert theme="danger">
        Form not found. Please contact your support team.
      </Alert>
    );
  }

  if (view?.toLowerCase() === "new" && !form?.Permissions.includes("create")) {
    return (
      <Alert theme="warning">
        Form type <span className="font-medium">{form?.FormTitle}</span> cannot
        be created. Please contact your support team.
      </Alert>
    );
  }

  const hasTerms = form?.Terms === "1" && !termsAccepted && view?.toLowerCase() === "new";
  if (hasTerms) {
    return (
      <DynamicFormTerms
        termsUrl={getTermsUrl(form)}
        handleAcceptTerms={handleAcceptTerms}
      />
    );
  }

  if (FormComponent === null) {
    return null;
  }

  return (
    <>
      <FormComponent
        handleFormSubmit={handleFormSubmit}
        dataStatus={dataStatus}
        form={form}
        parentForm={parentForm}
        farm={farm}
        house={house}
        formValues={unsavedFormData}
        prevFormValues={previousFormData}
        parentFormData={parentFormData}
        formValid={formValid}
        standards={standards}
        birdAge={birdAge}
        birdsAlive={birdsAlive}
        setFieldValue={setFieldValue}
        setFieldValid={setFieldValid}
        removeFieldValid={removeFieldValid}
        validateField={validateField}
        formProps={{
          requiredFieldCount: requiredFormFieldCount,
          requiredFieldCompletedCount: requiredFieldCompletedCount,
          className: "flex flex-col flex-grow",
        }}
        view={view}
        showSections={view === "nonconformance" ? false : true}
        labelPosition={
          moduleFeatureGroup?.toLowerCase() === "audit" ? "top" : null
        }
        schedule={schedule}
      />
    </>
  );
}

function getTermsUrl(form: IForm) {
  if (!form?.FormType || !form?.FormName) return undefined;

  let client = window.location.hostname.split(".").reverse()[2];
  const formType = form.FormType.toLowerCase();
  const formName = form.FormName.toLowerCase();

  /**
   * Uncomment the following line to test on local development
   * overwrite subdomain with client subdomain
   */
  if (process.env.NODE_ENV === "development") client = "dev";

  return `/${client}/${formType}/${formName}/terms-conditions.json`;
}
