import { Alert, Button, Checkbox } from "components/core";
import { useFarmGetMany } from "hooks/useFarm";
import { useSearchParams } from "react-router-dom";
import FarmSelectSummary from "./farm-select-summary";
import { useDeferredValue, useEffect, useMemo, useState } from "react";
import { type IFarm } from "helpers/farmUtilities";
import classNames from "classnames";
import FarmSearch from "./farmsearch";

type FarmSelectProps = {
  checkedFarms: string[];
  setCheckedFarms: React.Dispatch<React.SetStateAction<string[]>>;
};

const FarmSelect: React.FC<FarmSelectProps> = ({
  checkedFarms,
  setCheckedFarms,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isEditingFarmPermissions =
    searchParams.get("action") === "farm-permissions";

  const { data: farms } = useFarmGetMany();

  const [filteredFarms, setFilteredFarms] = useState(farms);
  const deferredFilteredFarms = useDeferredValue(filteredFarms);

  useEffect(() => {
    setFilteredFarms(farms || []);
  }, [farms]);

  const selectedFarms = useMemo(
    () => farms?.filter((farm) => checkedFarms.includes(farm.FarmCode)) ?? [],
    [checkedFarms, farms]
  );

  if (!farms) {
    return <Alert theme="danger">No farms found</Alert>;
  }

  return (
    <>
      {isEditingFarmPermissions ? (
        <>
          <Button
            type="button"
            onClick={() => {
              setSearchParams((params) => {
                params.delete("action");
                return params;
              });
            }}
          >
            Hide assigned farms
          </Button>
          <FarmSearch farms={farms} setFilteredFarms={setFilteredFarms} />
          <FarmSelectTable
            farms={farms}
            checkedFarms={checkedFarms}
            setCheckedFarms={setCheckedFarms}
            filteredFarms={deferredFilteredFarms}
          />
        </>
      ) : (
        <>
          <Button
            type="button"
            onClick={() => {
              setSearchParams({ action: "farm-permissions" });
            }}
          >
            Select Farms...
          </Button>

          <FarmSelectSummary selectedFarms={selectedFarms} />
        </>
      )}
    </>
  );
};

export default FarmSelect;

type FarmSelectTableProps = {
  farms: IFarm[];
  checkedFarms: string[];
  setCheckedFarms: React.Dispatch<React.SetStateAction<string[]>>;
  className?: string;
  filteredFarms: IFarm[];
};

const FarmSelectTable: React.FC<FarmSelectTableProps> = ({
  farms,
  checkedFarms,
  setCheckedFarms,
  filteredFarms,
}) => {
  const [checkedMasterToggle, setCheckedMasterToggle] =
    useState<boolean>(false);

  useEffect(() => {
    if (checkedFarms.length === farms.length) {
      setCheckedMasterToggle(true);
    } else {
      setCheckedMasterToggle(false);
    }
  }, [checkedFarms, farms]);

  return (
    <div className="flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          <div className="relative">
            <table className="min-w-full table-fixed divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="relative px-7 tablet:w-12 tablet:px-6"
                  >
                    <Checkbox
                      label=""
                      checked={checkedMasterToggle}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setCheckedFarms(farms.map((f) => f.FarmCode));
                        } else {
                          setCheckedFarms([]);
                        }
                      }}
                    />
                  </th>
                  <th
                    scope="col"
                    className="font-semibold min-w-[12rem] py-3.5 pr-3 text-left text-sm text-gray-900"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="font-semibold px-3 py-3.5 text-left text-sm text-gray-900"
                  >
                    Code
                  </th>
                  {/* <th
                    scope="col"
                    className="relative py-3.5 pl-3 pr-4 tablet:pr-3"
                  >
                    <span className="sr-only">Edit</span>
                  </th> */}
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {filteredFarms.map((farm) => (
                  <tr
                    key={farm.FarmCode}
                    className={
                      checkedFarms.includes(farm.FarmCode)
                        ? "bg-gray-50"
                        : undefined
                    }
                  >
                    <td className="relative px-7 tablet:w-12 tablet:px-6">
                      {checkedFarms.includes(farm.FarmCode) && (
                        <div className="bg-indigo-600 absolute inset-y-0 left-0 w-0.5" />
                      )}
                      <Checkbox
                        label=""
                        value={farm.FarmCode}
                        checked={checkedFarms.includes(farm.FarmCode)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            console.log("checked", farm.FarmCode);
                            setCheckedFarms((prev) => [...prev, farm.FarmCode]);
                          } else {
                            setCheckedFarms((prev) =>
                              prev.filter((f) => f !== farm.FarmCode)
                            );
                          }
                        }}
                      />
                    </td>
                    <td
                      className={classNames(
                        "whitespace-nowrap py-4 pr-3 text-sm font-medium",
                        checkedFarms.includes(farm.FarmCode)
                          ? "text-indigo-600"
                          : "text-gray-900"
                      )}
                    >
                      {farm.FarmName}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {farm.FarmCode}
                    </td>
                    {/* <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium tablet:pr-3">
                      <a
                        href="#"
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        Edit<span className="sr-only">, {farm.FarmName}</span>
                      </a>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
